import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import userActions from "../user/actions";
import modalActions from "../modals/actions";
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";


export function* fetchUsers() {

  yield takeLatest(actions.FETCH_USERS, function*() {
    try {

      yield put(actions.toggleUsersLoader(true));

      const response = yield call(ApiProvider.axiosWithToken.get, "/users");

      const users = response.data.users;

      yield put(actions.updateUsers(users));

      yield put(actions.toggleUsersLoader(false));

    } catch (error) {

      yield put(actions.toggleUsersLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* fetchConfig() {

  yield takeLatest(actions.FETCH_CONFIG, function*() {
    try {

      yield put(actions.toggleConfigLoader(true));

      const response = yield call(ApiProvider.axiosWithToken.get, "/admin/config");

      yield put(actions.updateConfig(response.data));

      yield put(actions.toggleConfigLoader(false));

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* addRate() {

  yield takeLatest(actions.REQ_ADD_RATE, function*({payload}) {

    const {type, value1, value2, beginAt} = payload;

    try {

      yield put(actions.toggleConfigLoader(true));

      yield call(ApiProvider.axiosWithToken.post, "/rates", {
        type,
        value1,
        value2,
        beginAt
      });

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* deleteRate() {

  yield takeLatest(actions.REQ_DELETE_RATE, function*({payload}) {

    const {id} = payload;

    try {

      yield put(actions.toggleConfigLoader(true));

      yield call(ApiProvider.axiosWithToken.delete, `/rates/${id}`);

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* editRate() {

  yield takeLatest(actions.REQ_EDIT_RATE, function*({payload}) {

    const {value1, value2, beginAt, id} = payload;

    try {

      yield put(actions.toggleConfigLoader(true));

      yield call(ApiProvider.axiosWithToken.put, `/rates/${id}`, {
        value1,
        value2,
        beginAt
      });

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* addConfigDocument() {
  yield takeLatest(actions.REQ_ADD_CONFIG_DOCUMENT, function*({payload}) {

    yield put(actions.toggleConfigLoader(true));

    const { file, name, type } = payload;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("type", type);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    try {
      yield call(
        ApiProvider.axiosWithToken.post,
        `/config-documents`,
        formData,
        config
      );

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {
      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* addDiscountCode() {

  yield takeLatest(actions.REQ_ADD_DISCOUNT_CODE, function*({payload}) {

    const {beginAt, endAt, code, value, referralEmail} = payload;

    try {

      yield put(actions.toggleConfigLoader(true));

      yield call(ApiProvider.axiosWithToken.post, "/discount-codes", {
        beginAt, endAt, code, value, referralEmail
      });

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));
      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* editDiscountCode() {

  yield takeLatest(actions.REQ_EDIT_DISCOUNT_CODE, function*({payload}) {

    const {beginAt, endAt, code, value, referralEmail, id} = payload;

    try {

      yield put(actions.toggleConfigLoader(true));

      yield call(ApiProvider.axiosWithToken.put, `/discount-codes/${id}`, {
        beginAt, endAt, code, value, referralEmail
      });

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* deleteDiscountCode() {

  yield takeLatest(actions.REQ_DELETE_DISCOUNT_CODE, function*({payload}) {

    const {id} = payload;

    try {

      yield put(actions.toggleConfigLoader(true));

      yield call(ApiProvider.axiosWithToken.delete, `/discount-codes/${id}`);

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* addConfigPdf() {
  yield takeLatest(actions.REQ_ADD_CONFIG_PDF, function*({payload}) {

    yield put(actions.toggleConfigLoader(true));

    const { type, footer } = payload;

    try {

      yield put(actions.toggleConfigLoader(true));

      yield call(ApiProvider.axiosWithToken.post, "/config-pdfs", {
        type, footer
      });

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchConfig());

    } catch (error) {

      yield put(actions.toggleConfigLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* addUser() {
  yield takeLatest(actions.REQ_ADD_USER, function*({payload}) {

    yield put(actions.toggleConfigLoader(true));

    const { email, pwd } = payload;

    try {

      yield put(actions.toggleUsersLoader(true));

      yield call(ApiProvider.axiosWithToken.post, "/users/register-confirm", {
        email, pwd
      });

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);

      yield put(actions.fetchUsers());

      yield put(modalActions.hideModal('adminAddUser'));

    } catch (error) {

      yield put(actions.toggleUsersLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* deleteUser() {

  yield takeLatest(actions.REQ_DELETE_USER, function*({payload}) {

    const {id} = payload;

    try {

      yield put(actions.toggleUsersLoader(true));

      yield call(ApiProvider.axiosWithToken.delete, `/users/${id}`);

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);
      yield put(actions.fetchUsers());

    } catch (error) {

      yield put(actions.toggleUsersLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* changeUser() {

  yield takeLatest(actions.REQ_CHANGE_USER, function*({payload}) {

    const {id} = payload;

    try {

      const response = yield call(ApiProvider.axiosWithToken.get, `/users/change/${id}`);

      yield put(userActions.logUser(response.data.token));

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* updateUser() {
  yield takeLatest(actions.REQ_UPDATE_USER_PREPAID_AMOUNT, function*({payload}) {

    const { id, prepaidAmount } = payload;

    try {

      yield put(actions.toggleUsersLoader(true));

      yield call(ApiProvider.axiosWithToken.put, `/users/update/${id}`, {
        prepaidAmount
      });

      notificationHelper.showSuccess('Nickel !', `Vos modifications ont été enregistré`, 3);

      yield put(actions.fetchUsers());

      yield put(modalActions.hideModal('adminUpdateUser'));

    } catch (error) {

      yield put(actions.toggleUsersLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}



export function* reqfetchAdminRelances(){
  yield takeLatest(actions.FETCH_ADMIN_RELANCES,function*({payload}){
      try {
        yield put(actions.relancesLoading(true))
        const response = yield call(ApiProvider.axiosWithToken.get,"/admin/orders");

        const orders = response.data?.orders || [];

        yield put(actions.updateRelances(orders))
        yield put(actions.relancesLoading(false))
      } catch (error) {
        yield put(actions.relancesLoading(false))
        if(error.response.data.error){
          notificationHelper.showError(error.response.data.error);
        }else{
          notificationHelper.showError('SERVER_ISSUE');
        }
      }
  });
}


export function* reqSaveOrderRelance(){
  yield takeLatest(actions.SAVE_ORDER_ADMIN, function*({payload}) {

    try {
       const {file,data} = payload;
       console.log(data);
       const formData = new FormData();
       formData.append('data',JSON.stringify(data));
       if (file)
        formData.append("file", file.originFileObj);

       const config = { headers: { "Content-Type": "multipart/form-data" } };

       yield call(
          ApiProvider.axiosWithToken.post,
          "/admin/order",
          formData,
          config
       )

       notificationHelper.showSuccess(
        "Success",
        "Le suivi a bien été enregistré.",
        4
       )
       yield put(actions.openModalRelance(false));
       yield put(actions.fetchAdminRelances());
      
    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* reqFetchHistory (){
  yield takeLatest(actions.REQ_FETCH_RELANCE_HISTORY, function*({payload}){
    try {
      const response = yield call(ApiProvider.axiosWithToken.get, `/admin/order/history/${payload}`);
      const {histories} = response.data;

      yield put(actions.updateRelanceHitory(histories));
      
    } catch (error) {
      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  })
}


export default function* rootSaga() {
  yield all([
    fetchUsers(),
    fetchConfig(),
    addRate(),
    deleteRate(),
    editRate(),
    addConfigDocument(),
    addDiscountCode(),
    editDiscountCode(),
    deleteDiscountCode(),
    addConfigPdf(),
    addUser(),
    deleteUser(),
    changeUser(),
    updateUser(),
    reqfetchAdminRelances(),
    reqSaveOrderRelance(),
    reqFetchHistory(),
  ])
}
