import { cloneDeep } from "lodash";
import actions from "./actions";


const initState = {
    clients : [],
    isLoading: false,
    open: false,
    error: null,
    modifyClientsDone: false,
    addClientDone: false
}

export default function rootReducer(state= initState,action){
    const newState = cloneDeep(state);

    switch (action.type){
        case actions.UPDATE_CLIENTS:
            newState.clients = action.payload;
            return newState;

        case actions.IS_LOADING:
            newState.isLoading = action.payload;
            return newState;
        
        case actions.IS_OPEN:
            newState.open = action.payload;
            return newState;
            
        case actions.ADD_CLIENT_SUCCESS:
            return {
                ...newState,
                clients: [...newState.clients, action.payload],
                addClientDone: true
            };
        
        case actions.ADD_CLIENT_RESET:
            return {
                ...newState,
                addClientDone: false
            };

        default:
            return state;
    }
}
