export default {
    approuvee: "Approuvée",
    approuvee_partielle: "Accord partiel",
    virement: "Virement",
    inProgress: "En cours",
    reglee: "Réglée",
    reglementEnCours: "Règlement en cours",
    suspendue: "Suspendue",
    refusee: "Refusée",
    abandoned: "Abandon",
    pending : 'En attente',
    paid : 'Encaissé',
  };
  