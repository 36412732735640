import styled from "styled-components";
import { colors } from "../../../helpers/appColors";

const StyleWrapper = styled.div`
    .detail-column{
        border: 0px solid!important;;
        background-color: white!important;;
    }
      .row-suspendue{
        background-color: ${colors.suspendue};
      }
    
      .row-reglee {
        background-color: ${colors.reglee};
      }
    
      .row-reglementEnCours {
        background-color: ${colors.reglementEnCours};
      }
    
      .row-approuvee {
        background-color: ${colors.approuvee};
      }
    
      .row-abandoned{
        background-color: ${colors.abandoned};
      }
    
      .row-refusee{
        background-color: ${colors.refusee};
      }

`

export default StyleWrapper