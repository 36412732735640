import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";
import history from "../../helpers/history";

export function* compute() {

  yield takeLatest(actions.REQ_COMPUTE, function*({payload}) {

    const {
      commercialFixedAnnualRate,
      individualFixedAnnualRate,
      companyName,
      invoices,
      rateType,
      submitType
    } = payload;

    try {

      const response = yield call(ApiProvider.axiosWithToken.post, "/compute", {
        commercialFixedAnnualRate,
        individualFixedAnnualRate,
        companyName,
        invoices,
        rateType,
        submitType
      });

      const {globalPenalties, globalBalance} = response.data;

      yield put(actions.updatePenaltiesInfo(globalBalance,globalPenalties));

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });

  yield takeLatest(actions.FETCH_MY_COMPANIES, function*() {

    try {

      const response = yield call(ApiProvider.axiosWithToken.get, "/companies");

      const {companies} = response.data;

      yield put(actions.updateMyCompanies(companies));

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });

  yield takeLatest(actions.REQ_GENERATE_ORDER, function*({payload}) {

    try {

      const {formValues} = payload;

      const formData = new FormData();
      Object.keys(formValues).forEach(key => {
        if('invoices' === key){
          formData.append(key, JSON.stringify(formValues[key]));
        }else if(Array.isArray(formValues[key])){
          for(let i = 0; i < formValues[key].length; i++){
            formData.append(key, formValues[key][i].originFileObj);
          }
        }else{
          formData.append(key, formValues[key]);
        }
      });

      console.log(formValues,formData);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      const response = yield call(ApiProvider.axiosWithToken.post,
        "/orders",
        formData,
        config);

      const { order } = response.data;


      // Redirect to summary page with order ID
      history.push({
        pathname: `/order/${order.id}`,
        state: { timestamp: Date.now() } // Ajoute une propriété timestamp à l'objet state
      });

      // Summary page fetch order ID and display info if it is the owner

    } catch (error) {

      console.error(error);

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });

  yield takeLatest(actions.REQ_INVOICES, function*({payload}) {
      try{
          const { data , id } = payload;

          const response = yield call(ApiProvider.axiosWithToken.get,`/reminders/${id}`);
          
          const invoicesFeched = response.data.invoices;

          const invoices = JSON.parse(data.invoices);

          invoices.forEach(invoice=>{
            let temp = invoicesFeched.find( temp => temp.id === invoice.id);
            if(temp){
              invoice.factureName = temp.factureName;
              invoice.factureUrl = temp.factureUrl; 
            }
          });

          data.invoices = JSON.stringify(invoices);

          yield put(actions.updateDefaultFormValues(data));
          history.push('/start')

      }catch(error){
        console.error(error);

        if(error.response.data.error){
          notificationHelper.showError(error.response.data.error);
        }else{
          notificationHelper.showError('SERVER_ISSUE');
        }
      }
  });
}


export default function* rootSaga() {
  yield all([
    fork(compute),
  ])
}
