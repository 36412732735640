import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  reminder: null,
  fileUpload: null,
  loading: false,
  payWithBridge: null,
  computeStatus: null
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case actions.UPDATE_REMINDER:
      newState.reminder = action.payload;
      return newState;

    case actions.UPDATE_FILE_UPLOAD:
      newState.fileUpload = action.payload;
      return newState;
    case actions.IS_LOADING:
      newState.loading = action.payload;
      return newState;
    case actions.PAY_WITH_BRIDGE:
      newState.payWithBridge = action.payload;
      return newState;
    case actions.UPDATE_COMPUTE_STATUS:
      newState.computeStatus = action.payload;
      return newState;
    case actions.RESET_COMPUTE_STATUS:
      newState.computeStatus = null;
      return newState;
    default:
      return state;
  }
}
