
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "./CustomModal.style";
import modalsActions from '../../../Redux/modals/actions';
import { Button, Col, Row, Tooltip } from "antd";
import { colors } from "../../../helpers/appColors";
import PricingTag from "../Helpers/PricingTag";
import styled from "styled-components";
import { FormInput } from "../../../helpers/forms";
import RichTextEditor from "../Profile/RichTextEditor";
import React, { useEffect, useRef } from "react";
import moment from "moment";
import { generateReference, getCompanyEmail, getCompanyName, randomString, replaceTags } from "../../../helpers/utils";
import NumberFormat from "react-number-format";
import { set } from "lodash";

const Content = styled.div`
    padding: 10px;
    width: 85%;
    margin: 0px auto;
`

const Label = styled.p`
    font-weight: bold;
    color: ${colors.textGrey};
    span{
        color: red;
    }
`

export default function MailRelance({formRef}) {

    const editorRefs = {
        objectMail : React.createRef(),
        contentMail : React.createRef(),
    }

    const activeEditorRef = useRef(null);
    const savedSelection = useRef(null);
    const defaultObjectMail = useSelector(state => state.calculator.defaultFormValues?.objectMail ?? null);
    const defautlContentMail = useSelector(state => state.calculator.defaultFormValues?.contentMail ?? null);
    
    const [objectMail, setObjectMail] = React.useState(defaultObjectMail);    
    const [contentMail, setContentMail] = React.useState(defautlContentMail);
    const [disabled,setDisabled] = React.useState(false);

    // Access to the  state
    const user = useSelector(state => state.user.info);
    const customers = useSelector(state => state.calculator.myCompanies || []);
    const visible = useSelector(state => state.modals.mailRelanceVisible);
    const globalBalance = useSelector(state => state.calculator.globalBalance);
    const penalite = useSelector(state => state.calculator.globalPenalties);
    const balance = globalBalance + penalite;

    // Access to the dispatch function
    const dispatch = useDispatch();
    const hideModal = () => dispatch(modalsActions.hideModal('mailRelance'));

    const saveSelection = (editorRef) => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          savedSelection.current = selection.getRangeAt(0);
          activeEditorRef.current = editorRef.current; // Stocke l'éditeur actif
        }
      };

    const onContentChange = (content) => {
        setContentMail(content);
    }

    const onObjectChange = (object) => {
        setObjectMail(object);
    }

    const onFinish = () => {
        formRef.current.setFieldsValue({
            submitType: 'mail_relance',
            objectMail: objectMail,
            contentMail: contentMail,
        });
        formRef.current.submit();
    }

    useEffect(() => {
        if(visible){
            const tempDisabled = (
                !user.documentUrl
                && user.subscriptionType === 'Free'
                && moment(user.createdAt).add(7, 'days').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
            )
            setDisabled(tempDisabled);
            const formsvalue = formRef.current.getFieldsValue();
            console.log(formsvalue);
            const nomDebiteur = formsvalue.companyId ? getCompanyName(formsvalue.companyId,customers) : formsvalue.companyName || null;
            const companyName = user.companyName || null;
            const reference = generateReference(formsvalue.reference, user.id);
            
            const mailRelance = formsvalue.companyId ? getCompanyEmail(formsvalue.companyId,customers) : null;
            

            formRef.current.setFieldsValue({mailRelanceMail:mailRelance,reference: reference});
            const tags = {
                reference: reference,
                principal: globalBalance.toFixed(2)+' €',
                penalite: penalite.toFixed(2)+' €',
                balance: balance.toFixed(2)+' €',
                companyName: nomDebiteur,
                entrepriseName: companyName
            };
            // replace tags
            setObjectMail(replaceTags(user.objectMail, tags));
            setContentMail(replaceTags(user.contentMail, tags));
        }
    },[visible])

    return (
    <CustomModal
        title="Mail de relance"
        visible={visible}
        onCancel={hideModal}
        footer={null}
        style={{textAlign: 'left'}}
        destroyOnClose={true}
    >
        <Row gutter={16} style={{textAlign: 'center', margin: '0', background: colors.primaryGreen}}>
            <Col span={6}><PricingTag title={'FREE'} subtitle={'Gratuit*'} active={'Free' === user.subscriptionType}/></Col>
            <Col span={6}><PricingTag title={'STARTER'} subtitle={'Gratuit'} active={'Starter' === user.subscriptionType}/></Col>
            <Col span={6}><PricingTag title={'PREMIUM'} subtitle={'Gratuit'} active={'Premium' === user.subscriptionType}/></Col>
            <Col span={6}><PricingTag title={'PRIVILÈGE'} subtitle={'Gratuit'} active={'Privilege' === user.subscriptionType}/></Col>
            <Col span={24} style={{marginBottom: 10, color: "white"}}>
                <em>* Gratuit la première semaine</em>
            </Col>
        </Row>
        <Content>
            <p style={{textAlign: 'center'}}>
                <em>
                    Modifiez le modéle de mail dans les options des votre compte
                </em>
            </p>

            {/* FORM */}
            <Row gutter={16}>
                <Col span={16}>
                    <FormInput
                        name={'mailRelanceMail'}
                        label={'Adresse mail de votre débiteur'}
                        placeholder={'Email'}
                        type='email'
                        rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Label>Objet <span>*</span></Label>
                    <RichTextEditor editorRef={editorRefs.objectMail} minHeight={"30px"} maxWidth={"100%"} defaultContent={objectMail} onChange={onObjectChange} onSelectionChange={()=>saveSelection(editorRefs.objectMail)}  >
                    </RichTextEditor>
                </Col>
            </Row>
            <br/>
            <Row gutter={16}>
                <Col span={24}>
                    <Label>Message <span>*</span></Label>
                    <RichTextEditor editorRef={editorRefs.contentMail} minHeight={"300px"} maxWidth={"100%"} defaultContent={contentMail} onChange={onContentChange} onSelectionChange={()=>saveSelection(editorRefs.contentMail)} >
                    </RichTextEditor>
                </Col>
            </Row>
            <p style={{textAlign: 'center', marginTop: 20}}>
                <em>Montant en principal: </em> 
                <strong>
                    <NumberFormat
                        displayType={'text'}
                        value={globalBalance}
                        decimalSeparator={','}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator={' '}
                        suffix={'€'}
                    />
                </strong>
                <br/>
                <em>Pénalités de retard calculées: </em>
                <strong>
                    <NumberFormat
                        displayType={'text'}
                        value={penalite}
                        decimalSeparator={','}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator={' '}
                        suffix={'€'}
                    />
                </strong>
            </p>
            <Row gutter={16}>
                <Col span={24} style={{textAlign: 'center', marginTop: 10}}>
                    {
                        (disabled ) ?  
                        <Tooltip title = {`Fonctionnalité disponible sur abonnement à partir de 9,90 € ht / mois. Rdv sur la page "Ma formule" pour souscrire à un de nos abonnements.`}>
                            <Button disabled={true} type="primary" >Visualiser</Button>
                        </Tooltip> 
                        : 
                        <Button type="primary" onClick={onFinish}>Visualiser</Button>
                    }
                  
                </Col>
            </Row>
        </Content>
    </CustomModal>
  )
}