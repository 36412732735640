/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable default-case */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import userActions from "../../../Redux/user/actions";
import reminderActions from "../../../Redux/reminders/actions";
import calculatorActions from "../../../Redux/calculator/actions";
import orderActions from "../../../Redux/order/actions";
import moment from 'moment';
import _ from "lodash";
import NumberFormat from 'react-number-format';
import { colors } from '../../../helpers/appColors';
import history from "../../../helpers/history";
import mySendingBoxStatus from "../../../helpers/mySendingBoxStatus";
import courrierLabels from "../../../helpers/courrierLabels";
import statusLabel from '../../../helpers/statusLabel';
import {CheckOutlined,QuestionCircleOutlined, CopyOutlined, DeleteOutlined,UploadOutlined} from '@ant-design/icons';
import suiviStatus from '../../../helpers/suiviStatus';
import { Table, Button, Tooltip, Input, Select, Popconfirm, Form, Upload, Space } from 'antd';
import { showError, showSuccess } from '../../../helpers/notificationHelper';
import CustomerHeader from '../Header/CustomerHeader';
import StyledModal from './StyleModal';
import { v4 as uuidv4 } from 'uuid'; 
import apiConfig from '../../../config/api.config';
import ReminderDetailModal from './ReminderDetailModal';
const {Option} =Select;

const SelectActionOptions = ["Nouvelle relance", "supprimer", "nouvelle_relance"];



class CustomerReminders extends Component{

  state = {
    searchValue: null,
    selectedRows: [],
    disabledDelete: true,
    disabledSubmit:true,
    selectedOption: SelectActionOptions,
    activeSousMenu: null,
    menuOuvertRemind: false,
    clickedItem : null,
    selectedSuivi : 'inProgress',
    annotation : null
  };


  formRef = React.createRef();

  componentDidMount(){
    this.props.fetchReminders();
  }

  componentDidUpdate(prevProps){
    if(prevProps.openModal !== this.props.openModal && this.props.openModal === false) {
      this.setState({annotation:null})
    }
  }

  handleSubmitAction = () => {
    
    let references = [];
    for(let option of this.state.selectedRows){
      console.log(option);
      references.push(option.reference);
    }
    console.log(references);
    this.props.bulkDeleteReminder(references);
    this.setState({
      selectedRows: [],
      disabledSubmit: true,
      disabledDelete: true
    });


  };

  handleNextAction = (nextAction, formValues) => {

    this.props.reqFetchMyCompanies();

    const data = _.cloneDeep(formValues);
    let path;
    data.step = 3;
    switch (nextAction){
      case 'unpaid_notice':
        path = '/amiable';
        data.submitType = 'unpaid_notice';
        data.gender = data.reminderGender
        data.company = data.reminderCompanyName
        data.adresse = data.reminderAddress
        data.zip = data.reminderZip
        data.city = data.reminderCity
        delete data.reminderGender
        delete data.reminderCompanyName
        delete data.reminderAddress
        delete data.reminderZip
        delete data.reminderCity
        delete data.reminderHasStickers
        delete data.reminderSelectedSticker
        delete data.reminderDelegatedSending
        break;
      case 'mise_en_demeure':
        path = '/amiable';
        data.submitType = 'mise_en_demeure';
        data.gender = data.unpaidNoticeGender
        data.company = data.unpaidNoticeCompanyName
        data.adresse = data.unpaidNoticeAddress
        data.zip = data.unpaidNoticeZip
        data.city = data.unpaidNoticeCity
        delete data.unpaidNoticeGender
        delete data.unpaidNoticeCompanyName
        delete data.unpaidNoticeAddress
        delete data.unpaidNoticeZip
        delete data.unpaidNoticeCity
        delete data.unpaidNoticeHasStickers
        delete data.unpaidNoticeSelectedSticker
        delete data.unpaidNoticeDelegatedSending
        break;
      case 'mail_direct_recouvrement':
        path = '/contentieux';
        data.submitType = 'mail_direct_recouvrement';
        break;
      case 'courrier_direct_recouvrement':
        path = '/contentieux';
        data.submitType = 'courrier_direct_recouvrement';
        break;
      case 'mise_en_demeure_direct_recouvrement':
        path = '/contentieux';
        data.submitType = 'mise_en_demeure_direct_recouvrement';
        break;
      case 'injonction':
        path = '/judiciaire';
        data.submitType = 'injonction';
        break;
    }

    setTimeout(() => {
      this.props.updateDefaultFormValues(data);
      history.push(path);
    }, 1000)
  };


  handleCopy = () => {

    // Update the selectedOption state (optional, if needed)
    this.setState((prevState) => ({ menuOuvertRemind: !prevState.menuOuvertRemind }));
    
    if(this.state.selectedRows.length>1){
      showError('ONLY_REMINDER')
    }else{
      const id = _.cloneDeep(this.state.selectedRows[0].key);
      const data = _.cloneDeep(this.state.selectedRows[0].formValues);
      data.step = 1;

      if (data.reference===null)  delete data.reference;

      delete data.hasStickers
      delete data.selectedSticker
      delete data.delegatedSending

      switch (data.submitType){
        case 'reminder':
          delete data.submitType
          delete data.reminderHasStickers
          delete data.reminderSelectedSticker
          delete data.reminderDelegatedSending
          break;
        case 'unpaid_notice':
          delete data.submitType
          delete data.unpaidNoticeHasStickers
          delete data.unpaidNoticeSelectedSticker
          delete data.unpaidNoticeDelegatedSending
          break;
        case 'mise_en_demeure':
          delete data.submitType
          delete data.miseEnDemeureHasStickers
          delete data.miseEnDemeureSelectedSticker
          delete data.miseEnDemeureDelegatedSending
          break;
      }

      for(const key in data){
        if(data[key]==='null') {
            data[key] = null
        }
      }


      const companyId = this.state.selectedRows[0]?.companyId;
      if ( companyId && !data.companyId){
        data.companyId = companyId;
      }
      

     // this.props.reqInvoices({data:data,id:id});
     this.props.updateDefaultFormValues(data);

     const selectedOptionUrl = this.state.selectedOption
    ? `/${this.state.selectedOption}`
    : '/main';

    this.setState({ menuOuvertRemind: !this.state.menuOuvertRemind });

  // Effectuez les opérations nécessaires avant la navigation

  // Naviguez vers l'URL spécifiée
     history.push(selectedOptionUrl)
    } 
  };

  handleOnchange= value =>{
    this.setState({selectedOption: value})
    if(this.state.selectedRows.length>0){
      this.setState({
        disabledSubmit: false,
      })
    }
  }

  openModal  = (record = null) => {

    if(record) {
      this.setState({clickedItem : record,selectedSuivi : record.currentStatus})
    }

    // FETCH HISTORIES
    this.props.reqFetchHistory(record.reference);

    this.props._openModal(true);
  }

  closeModal = ( ) => {
      this.setState({clickedItem:null,annotation:null})
      this.props.updateHistory([])
      this.props._openModal(false)
  }

  handleOk = () => {
    this.formRef.current.validateFields().then((values) => {
        const data = {
          reference : this.state.clickedItem.reference,
          comment : this.state.annotation ?  this.state.annotation.comment : '' ,
          status : (this.state.clickedItem.currentStatus !== this.state.selectedSuivi) ? this.state.selectedSuivi : ''
        }
        this.props.reqSaveOrder({data,file: this.state.annotation?.file});
    })
  }

  handleUploadFile = (event) => {
    const file = event.fileList[0];

    this.setState(prevState => ({
      annotation: { ...prevState.annotation, file: file }
    }));
  }

  handleRemove = () => {
    this.setState(prevState => ({
      annotation: { ...prevState.annotation, file: null }
    }));
  };

  addAnnotation = () => {
    const newAnnotation = {
      date: moment().format('DD/MM/YYYY'), 
      comment: '' ,
      file : null,
    };

    this.formRef.current.setFieldsValue({
      comment : ''
    })

    this.setState({ annotation: newAnnotation });
  };

  handleCommentChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      annotation: { ...prevState.annotation, comment: value }
    }));
  };


  render(){

    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (text, record, index) => {
          return (
            <button 
            onClick={()=> this.openModal(record)}
            className='btn-link'
          >
               <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>
            </button>
           
          )
        },
        sorter: (a,b) => a.createdAt < b.createdAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Nom / Raison sociale',
        dataIndex: 'companyName',
        sorter: (a,b) => a.companyName < b.companyName ? -1:1,
        render  : (text,record,index) => {
          return (
            <button 
            onClick={()=> this.openModal(record)}
            className='btn-link'
          >
               <span>{record.companyName}</span>
            </button>
          )
        },
        showSorterTooltip: false
      },
      {
        title: 'Principal',
        dataIndex: 'mainBalance',
        render: (text,record) => {
          return (
            <button 
            onClick={()=> this.openModal(record)}
            className='btn-link'
            >
              <span>
                <NumberFormat
                  displayType={'text'}
                  value={record.mainBalance}
                  decimalSeparator={','}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                  suffix={'€'}
                />
              </span>
            </button>
          )
        },
        sorter: (a,b) => a.mainBalance < b.mainBalance ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Relance effectuée',
        dataIndex: 'documentType',
        render: (text, record) => {
          return (
            <Button type='default' size={'small'} href={record.documentUrl} disabled={!record.documentUrl} target={'_blank'} rel="noreferrer">
              {courrierLabels[text]}
            </Button>
          )
        },
        sorter: (a,b) => courrierLabels[a.documentType] < courrierLabels[b.documentType] ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Référence',
        dataIndex: 'reference',
        showSorterTooltip: false,
        sorter: (a,b) => {
          const refA = a.reference && !(['reminder', 'unpaid_notice', 'mise_en_demeure'].includes(a.documentType)) ? a.reference : '';
          const refB = b.reference && !(['reminder', 'unpaid_notice', 'mise_en_demeure'].includes(b.documentType))? b.reference : '';
          return refA < refB ? -1:1
        },
        render : (text,record,index) => {
          return (
            <button 
            onClick={()=> this.openModal(record)}
            className='btn-link'
          >
               <span>{record.reference}</span>
            </button>
          )
        } 
      },
      {
        title: 'Suivi',
        dataIndex: 'currentStatus',
        sorter: (a,b) => {
          if(!a.currentStatus && b.currentStatus) return -1;
          return statusLabel[a.currentStatus] < statusLabel[b.currentStatus] ? -1:1
        },
        showSorterTooltip: false,
        render: (text, record, index) => {
          return <div style={{textAlign: 'center'}}>
            <button 
              className='btn-link'
              onClick={()=> this.openModal(record)}
            >
              {statusLabel[text]}
            </button>
          </div>;
        }
      },
      {
        title: (
          <span>
            Relance préconisée {' '}
            <Tooltip placement="top" title={`Notre algorithme vous suggère de nouvelles relances en fonction des informations enregistrées (montant, ancienneté, action précédente… )`}><QuestionCircleOutlined/></Tooltip>
          </span>
        ),
        showSorterTooltip: false,
        dataIndex: 'nextAction',
        sorter: (a,b) => moment(a.nextActionAt).format('YYYY-MM-DD') < moment(b.nextActionAt).format('YYYY-MM-DD') ? -1:1,
        render: (text, record, index) => {

          const {userInfo} = this.props;

          const disabled = (
            !record.documentUrl
            && userInfo.subscriptionType === 'Free'
            && moment(userInfo.createdAt).add(7, 'days').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
            && ['Rappel', `Avis d'impayé`, `Mise en demeure`].includes(courrierLabels[record.nextAction])
          )


          return (
            <div>
              {courrierLabels[record.nextAction] && !record.checkedMandateOption && (
                
                <div style={{textAlign:'center'}}>
                  <Tooltip title={disabled ? `Disponible à partir de l'offre starter` : courrierLabels[record.nextAction] }>
                    <Button
                      type='default'
                      style={!disabled ? {
                        backgroundColor: colors.primaryOrange,
                        borderColor: colors.primaryOrange,
                        color: 'white'
                      } : null}
                      size='small'
                      disabled={disabled}
                      onClick={() => this.handleNextAction(text, record.formValues)}
                    >
                      {courrierLabels[record.nextAction]}
                    </Button>
                  </Tooltip>
                  <br/><span
                  style={moment(record.nextActionAt).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? {color: colors.primaryRed} : null}>le {moment(record.nextActionAt).format('DD/MM/YYYY')}</span>
                </div>
              )}
              { courrierLabels[record.nextAction] && record.checkedMandateOption && (
                <div style={{textAlign:'center'}} >
                    Mandat
                </div>
              )
              }
            </div>
          )
        },
      },
      {
        title : '',
        dataIndex : 'reference',
        className : 'detail-column',
        render : (text,record,index) => {
          return (
            <>
           {
            record.currentStatus && (
              <button 
                onClick={()=> this.openModal(record)}
                style={{ textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 , color : colors.primaryGreen }}
              >
                <i>Détail</i>
              </button>
            )
           }
          </>
          )
        }
      }
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows: selectedRows
        })
        if (selectedRows.length > 0 ) {
          if (this.state.selectedOption[2] === "nouvelle_relance") {
            this.setState({
              disabledSubmit: true,
              disabledDelete: false,
            });
          }else{
          this.setState({
            disabledDelete:false,
            disabledSubmit:false,
          });}
        } else {
          if (this.state.selectedOption[2] === "nouvelle_relance") {
            this.setState({
              disabledSubmit: true,
              disabledDelete: true,
            });
          }else{
          this.setState({
            disabledSubmit: true,
            disabledDelete: true,
          });}
        }
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    let data = [];

    this.props.reminders.map((reminder, idx) => {

      if(reminder.type === 'document'){
        data.push({
          key: reminder.id,
          createdAt: reminder.createdAt,
          companyName: reminder.companyName,
          mainBalance: reminder.mainBalance,
          penalties: reminder.penalties,
          documentType: reminder.documentType,
          documentUrl: reminder.documentUrl,
          reference: (reminder.reference === 'null') ? null : reminder.reference,
          nextAction: reminder.nextAction,
          nextActionAt: reminder.nextActionAt,
          formValues: reminder.formValues,
          companyId: reminder.companyId,
          mailingTrackingNumber: reminder.mailingTrackingNumber,
          checkedMandateOption : reminder.checkedMandateOption,
          currentStatus : (reminder.currentStatus) ? reminder.currentStatus : null
        })
      }
    });

    if(this.state.searchValue) {
      data = data.filter(el => {
        el.reference = el.reference ? el.reference : '';
        const formattedMailingStatus = mySendingBoxStatus[el.mailingStatus] ? mySendingBoxStatus[el.mailingStatus] : '';
        const formattedLabel = courrierLabels[el.documentType] ? courrierLabels[el.documentType] : '';

        if ( el.companyName.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && el.mainBalance.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && el.penalties.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && formattedLabel.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && el.reference.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && formattedMailingStatus.toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        ) {
          return false;
        }else{
          return true;
        }
      });
    }

    const  clickedItem = this.state.clickedItem;
   
    return (
      <div>
        {/* Remplacer l'ancienne modale par le nouveau composant */}
        <ReminderDetailModal 
          formRef={this.formRef}
          openModal={this.props.openModal}
          clickedItem={this.state.clickedItem}
          selectedSuivi={this.state.selectedSuivi}
          annotation={this.state.annotation}
          histories={this.props.histories}
            onCancel={this.closeModal}
          onOk={this.handleOk}
          onSuiviChange={(value) => this.setState({ selectedSuivi: value })}
          onAddAnnotation={this.addAnnotation}
          onCommentChange={this.handleCommentChange}
          onUploadFile={this.handleUploadFile}
          onRemoveFile={this.handleRemove}
        />

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <Select value={this.state.selectedOption} placeholder="Nouvelle relance" style={{width:170}} onChange={this.handleOnchange}>
                <Option value="penalites">Pénalités de retard</Option>
                <Option value="amiable">Amiable</Option>
                <Option value="contentieux">Contentieux</Option>
                <Option value="judiciaire">Judiciaire</Option>
            </Select>
            <Button type={'primary'} disabled={this.state.disabledSubmit} style={{ marginLeft: 5 }} onClick={this.handleCopy}>
                <CheckOutlined />
            </Button>
            <CustomerHeader 
            selectedOption={this.state.selectedOption} menuOuvertRemind={this.state.menuOuvertRemind} onCopyClick={this.handleCopy}/>
                <Popconfirm
                title="Êtes-vous sûr de vouloir supprimer ces lignes ? Cette action est irréversible"
                onConfirm={this.handleSubmitAction}
                onCancel={() => false}
                okText="Oui"
                cancelText="Annuler"
              >
                <Button type={'primary'} disabled={this.state.disabledDelete} shape='circle' style={{ borderRadius: '50%', marginLeft: '12px' }} icon={<DeleteOutlined />}/>
              </Popconfirm> 
          </div>
          <div>
            <Button type={'default'} style={{marginRight: 5}} disabled={ data.length > 0 ? false : true } onClick={()=>this.props.exportToExcelReminders()}><UploadOutlined /> Exporter</Button>
            <Input style={{width: 300, marginBottom: 10}} placeholder='Rechercher' onChange={(e) => this.setState({searchValue: e.target.value})}/>
          </div>
        </div>
        <Table
          size={'small'}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowClassName={(record) => (record.status === 'inProgress') ? '' : `row-${record.currentStatus}`}
        />
      </div>

    )
  }
}

export default withRouter(connect(
  state => ({
    token: state.user.token,
    userInfo: state.user.info,
    reminders: state.reminders.reminders,
    defaultFormValues: state.calculator.defaultFormValues ,
    openModal : state.order.openModal,
    histories : state.order.histories
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
    fetchReminders: reminderActions.reqFetchReminders,
    reqFetchMyCompanies: calculatorActions.fetchMyCompanies,
    updateDefaultFormValues: calculatorActions.updateDefaultFormValues,
    bulkDeleteReminder: reminderActions.bulkDeleteReminder,
    exportToExcelReminders: reminderActions.exportToExcelReminders,
    reqInvoices : calculatorActions.reqInvoices, 
    _openModal : orderActions._openModal,
    reqSaveOrder :  orderActions.reqSaveOrder,
    reqFetchHistory : orderActions.reqFetchHistory,
    updateHistory : orderActions.updateHistory
  }
)(CustomerReminders));
