import { Button, Col, Input, Row, Table, Modal, Form, Select, Space, Upload } from "antd";
import StyleWrapper from "./StyleWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import adminActions from "../../../Redux/admin/actions";
import { render } from "@testing-library/react";
import courrierLabels from "../../../helpers/courrierLabels";
import statusLabel from "../../../helpers/statusLabel";
import { colors } from "../../../helpers/appColors";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { DeleteOutlined } from '@ant-design/icons';
import suiviStatus from '../../../helpers/suiviStatus';
import mySendingBoxStatus from "../../../helpers/mySendingBoxStatus";
import { v4 as uuidv4 } from 'uuid';
import apiConfig from '../../../config/api.config';
import StyledModal from "./StyleModal";
import actions from "../../../Redux/admin/actions";

const { Option } = Select;

export default function AdminRelances (){

    const dispatch = useDispatch();
    const {relances,relancesLoading,histories,openModal}  = useSelector(state=> state.admin);
    const formRef = useRef();

    // Ajout des états pour le modal
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedSuivi, setSelectedSuivi] = useState('inProgress');
    const [annotation, setAnnotation] = useState(null);

    // Ajout de l'état pour la recherche
    const [searchValue, setSearchValue] = useState(null);

    // Handlers pour le modal
    const handleOpenModal = (record) => {
        setSelectedItem(record);
        setSelectedSuivi(record.currentStatus || 'inProgress');
        dispatch(actions.reqFetchRelanceHistory(record?.reference))
        dispatch(actions.openModalRelance(true))

    };

    const handleCloseModal = () => {
        dispatch(actions.openModalRelance(false))
        setSelectedItem(null);
        setAnnotation(null);
    };

    const handleOk = () => {
        formRef.current.validateFields().then((values) => {
            const data = {
                reference: selectedItem.reference,
                comment: annotation ? annotation.comment : '',
                status: (selectedItem.currentStatus !== selectedSuivi) ? selectedSuivi : ''
            };
            dispatch(actions.reqSaveOrderAdmin({data,file:annotation?.file}));
        });
    };

    const handleAddAnnotation = () => {
        setAnnotation({
            date: moment().format('DD/MM/YYYY'),
            comment: '',
            file: null,
        });
    };

    const handleCommentChange = (event) => {
        setAnnotation(prev => ({
            ...prev,
            comment: event.target.value
        }));
    };

    const handleUploadFile = (event) => {
        setAnnotation(prev => ({
            ...prev,
            file: event.fileList[0]
        }));
    };

    const handleRemoveFile = () => {
        setAnnotation(prev => ({
            ...prev,
            file: null
        }));
    };

    useEffect(()=>{
        dispatch(adminActions.fetchAdminRelances())
    },[dispatch])

    useEffect(() => {
        if (!openModal) {
          setAnnotation(null);
        }
      }, [openModal]);

    // Filtrer les données en fonction de la recherche
    let filteredData = [...relances];
    
    if (searchValue) {
        filteredData = relances.filter(el => {
            const reference = el.reference || '';
            const creancier = el.User?.companyName || '';
            const debiteur = el.companyName || '';
            const suivi = statusLabel[el.currentStatus] || '';
            const searchUpperCase = searchValue.toUpperCase();

            return (
                reference.toString().toUpperCase().includes(searchUpperCase) ||
                creancier.toString().toUpperCase().includes(searchUpperCase) ||
                debiteur.toString().toUpperCase().includes(searchUpperCase) ||
                suivi.toString().toUpperCase().includes(searchUpperCase)
            );
        });
    }

    const columns = [
        {
            title : 'Réference relance',
            dataIndex : 'reference',
            showSorterTooltip: false,
            sorter: (a,b) => {
              const refA = a.reference && !(['reminder', 'unpaid_notice', 'mise_en_demeure'].includes(a.documentType)) ? a.reference : '';
              const refB = b.reference && !(['reminder', 'unpaid_notice', 'mise_en_demeure'].includes(b.documentType))? b.reference : '';
              return refA < refB ? -1:1
            },
        },
        {
            title : 'Relance effectuée',
            dataIndex : 'documentType',
            render : (text,record) => {
                return (
                    <Button type='default' size={'small'} href={record.documentUrl} disabled={!record.documentUrl} target={'_blank'} rel="noreferrer">
                      {courrierLabels[text]}
                    </Button>
                  )
            },
            sorter: (a,b) => courrierLabels[a.documentType] < courrierLabels[b.documentType] ? -1:1,
            showSorterTooltip: false

        },
        {
            title : 'Créancier',
            dataIndex : 'User',
            sorter: (a,b) => a.User.companyName < b.User.companyName ? -1:1,
            showSorterTooltip: false,
            render : (text,record) => {
                return(
                    <span>{record.User?.companyName}</span>
                )
            }
        },
        {
            title : 'Principal',
            dataIndex : 'mainBalance',
            render: (text,record) => {
               return(
                <span>{(record.mainBalance + record.penalties).toFixed(2)}</span>
               )
            },
            sorter: (a,b) => a.mainBalance < b.mainBalance ? -1:1,
            showSorterTooltip: false
        },
        {
            title : 'Débiteur',
            dataIndex : 'companyName',
            sorter: (a,b) => a.companyName < b.companyName ? -1:1,
            showSorterTooltip: false
        },
        {
            title : 'Suivi',
            dataIndex : 'currentStatus',
            render: (text) => {
                return (
                    <span>{statusLabel[text]}</span>
                )
            },
            sorter: (a,b) => {
                if(!a.currentStatus && b.currentStatus) return -1;
                return statusLabel[a.currentStatus] < statusLabel[b.currentStatus] ? -1:1
            },
            showSorterTooltip: false,
        },
        {
            title : '',
            dataIndex : 'reference',
            className : 'detail-column',
            render : (text,record,index) => {
                return(
                    <>
                        {
                            record.currentStatus && (
                                <button
                                  onClick={()=> handleOpenModal(record)}
                                    style={{ textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 , color : colors.primaryGreen }}
                                > 
                                    <i>Détail</i>    
                                </button>
                            )
                        }
                    </>
                )
            }
        },
        
    ]

    return (
        <StyleWrapper>
            <Row gutter={16} style={{justifyContent:'center'}}>
                <Col span={18} className="shadowed-block">
                    <h3>Relances</h3>
                    {/* Modification de la section des filtres */}
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div>
                            <Input 
                                style={{width: 300, marginBottom: 10}} 
                                placeholder='Rechercher'
                                onChange={(e) => setSearchValue(e.target.value)}
                                allowClear // Permet d'effacer facilement la recherche
                            />
                        </div>
                    </div>
                    <Table
                        loading={relancesLoading}
                        size="small"
                        columns={columns}
                        dataSource={filteredData} // Utiliser les données filtrées
                        rowClassName={(record) => (record.currentStatus === 'inProgress') ? '' : `row-${record.currentStatus}`}
                    >    
                    </Table>
                </Col>
              
            </Row>

            {/* Modal avec le même contenu que CustomerReminders */}
            <Form ref={formRef}>
                <StyledModal
                    title={`${selectedItem?.reference} / ${selectedItem?.companyName}`}
                    centered
                    open={openModal}
                    onCancel={handleCloseModal}
                    footer={[
                        <Button key={uuidv4()} onClick={handleOk} type="primary">
                            Enregistrer
                        </Button>,
                    ]}
                    width={1000}
                >
                    <div className='modal-content'>
                        <div className='m-head'>
                            <div></div>
                            <div className='text-principal'>
                                Principal = <NumberFormat
                                    displayType={'text'}
                                    value={selectedItem?.mainBalance}
                                    decimalSeparator={','}
                                    fixedDecimalScale
                                    decimalScale={2}
                                    thousandSeparator={' '}
                                    suffix={'€'}
                                />
                            </div>
                            <div className='option-suivi'>
                                <span>Suivi</span>
                                <Select 
                                    value={selectedSuivi} 
                                    placeholder="" 
                                    style={{width: 170}}
                                    onChange={(value) => setSelectedSuivi(value)}
                                >
                                    {Object.entries(suiviStatus).map(([key, label]) => (
                                        <Option key={key} value={key}>
                                            {label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {/* Historique */}
                        <div className='content-history'>
                            {histories.map((history) => (
                                <div key={uuidv4()}>
                                    <div className='history-item'>
                                        <div className='history-date'>
                                            {moment(history.createdAt).format("DD/MM/YYYY")}
                                        </div>
                                        {/* REMINDERS */}
                                        {history.actionType === "reminder" && (
                                            <div className='history-details'>
                                                <Button 
                                                    type='default' 
                                                    size='small' 
                                                    disabled={!history.details.documentUrl} 
                                                    href={history.details?.documentUrl} 
                                                    target={'_blank'} 
                                                    rel="noreferrer"
                                                >
                                                    {courrierLabels[history.details.documentType]}
                                                </Button>
                                                {history.details.documentType === "mail_direct_recouvrement" && (
                                                    <p>à {history?.details?.formValues?.mailDrMail}</p>
                                                )}

                                                <div className='mailing'>
                                                    <div className="m-status">
                                                        {mySendingBoxStatus[history.details.mailingStatus]}
                                                    </div>
                                                    <div className="m-numero">
                                                        <a 
                                                            target='_blank' 
                                                            href={`http://www.csuivi.courrier.laposte.fr/suivi/index?id=${history.details.mailingTrackingNumber}`} 
                                                            title='Suivi'
                                                        >
                                                            {history.details.mailingTrackingNumber}
                                                        </a>
                                                    </div>
                                                    <Space direction="vertical" style={{ width: '100%' }}>
                                                        {history.details.filingProof && (
                                                            <Button 
                                                                type="default" 
                                                                href={history.details.filingProof} 
                                                                target="_blank" 
                                                                rel="noreferrer" 
                                                                style={{ width: '100%' }}
                                                            >
                                                                Télécharger la preuve de dépôt
                                                            </Button>
                                                        )}
                                                        {history.details.deliveryProof && (
                                                            <Button 
                                                                type="default" 
                                                                href={history.details.deliveryProof} 
                                                                target="_blank" 
                                                                rel="noreferrer" 
                                                                style={{ width: '100%' }}
                                                            >
                                                                Télécharger l'accusé de réception
                                                            </Button>
                                                        )}
                                                    </Space>
                                                </div>
                                            </div>
                                        )}
                                        {/* DEBITEUR */}
                                        {history.actionType === "debtor" && (
                                            <div>
                                                {history.details?.status === 'reglee' ? (
                                                    <div className="d-message d-bold">Virement instantané reçu</div>
                                                ) : (
                                                    <>
                                                        <div className="d-status">
                                                            {statusLabel[history.details?.status]} 
                                                            <span className='company-name'>par {selectedItem?.companyName}</span>
                                                        </div>
                                                        {history.details?.raison && (
                                                            <div className="d-message">{history.details?.raison}</div>
                                                        )}
                                                        {history.details?.supportingDocument && (
                                                            <div className="d-document">
                                                                <a 
                                                                    href={`${apiConfig.baseUrl}/annotation/${history.details.supportingDocument}`} 
                                                                    target='_blank' 
                                                                    download
                                                                >
                                                                    {history.details?.supportingDocument}
                                                                </a>
                                                            </div>
                                                        )}
                                                        {history.details?.email && (
                                                            <div className="d-email">{history.details?.email}</div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {/* COMMENTAIRES */}
                                        {history.actionType === "comment" && (
                                            <div>
                                                {history.details?.comment && (
                                                    <div className="d-message">{history.details?.comment}</div>
                                                )}
                                                {history.details?.supportingDocument && (
                                                    <div className="d-document">
                                                        <a 
                                                            href={`${apiConfig.baseUrl}/annotation/${history.details.supportingDocument}`} 
                                                            target='_blank' 
                                                            download
                                                        >
                                                            {history.details?.supportingDocument}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <hr/>
                                </div>
                            ))}
                        </div>

                        {/* Section Annotation */}
                        {!annotation && (
                            <div className='annotation'>
                                <button onClick={handleAddAnnotation}>
                                    + Ajoutez des commentaires
                                </button>
                            </div>
                        )}

                        <div className='form'>
                            <div className='date'>
                                {annotation?.date}
                            </div>
                            {annotation && (
                                <div className='form-inputs'>
                                    <Form.Item
                                        name="comment"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez saisir les commentaires',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea 
                                            onChange={handleCommentChange} 
                                            placeholder='Ajouter des commentaires'
                                        />
                                    </Form.Item>
                                    {!annotation?.file && (
                                        <Upload 
                                            accept={'.pdf'} 
                                            maxCount={1} 
                                            onChange={handleUploadFile}
                                            fileList={[]}
                                        >
                                            <Button className='upload-btn' size='middle'>
                                                Parcourir...
                                            </Button>
                                        </Upload>
                                    )}
                                    <div>
                                        {annotation?.file && (
                                            <>
                                                <span className="name-file">
                                                    {annotation?.file?.name}
                                                </span>
                                                <Button
                                                    className="remove-btn-file"
                                                    onClick={handleRemoveFile}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </StyledModal>
            </Form>
        </StyleWrapper>
    )
}