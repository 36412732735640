/* eslint-disable no-unused-vars */
/* eslint-disable require-yield */
import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
// import clientsActions from '../clients/actions'
import clientsActions from '../clients/actions'
import reminderActions from "../reminders/actions"
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";
import moment from "moment";

export function* reqFetchInvoices() {

  yield takeLatest(actions.REQ_FETCH_INVOICES, function*() {

    try {

      const response = yield call(ApiProvider.axiosWithToken.get, "/invoices");

      const {invoices} = response.data;

      yield put(actions.updateInvoices(invoices));

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });

}

export function* reqBulkArchiveInvoices() {

  yield takeLatest(actions.BULK_ARCHIVE_INVOICE, function*({payload}) {

    try {

      const {ids, status} = payload

      yield call(ApiProvider.axiosWithToken.post, "/invoices/bulk", {ids, status});

      yield put(actions.fetchInvoices());

      notificationHelper.showSuccess(`Succès`, `Vos factures ont bien été mise à jour`, 5);

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });

}

export function* exportToExcel(){
  yield takeLatest(actions.EXPORT_TO_EXCEL,function*({payload}){
      try {
        const {isArchived} =payload;
        const setup={
          headers : {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType:'arraybuffer'
        }

        const response= yield call(ApiProvider.axiosWithToken.get, `/invoices/export-excel/${isArchived}`,setup);

        const outputFilename = `${"factures_"+moment({hours:0}).format('DD_MM_YYYY')}.xlsx`;
  
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

      } catch (error) {
        if(error.response.data.error){
          notificationHelper.showError(error.response.data.error);
        }else{
          notificationHelper.showError('SERVER_ISSUE');
        }
      }
  });
}

function* addInvoices(action){
  try {
    const {companyName,companyId,invoices} = action.payload;
    
    yield call(ApiProvider.axiosWithToken.post, "/invoices/bulk/add", {companyName,companyId,invoices});

    yield put(actions.fetchInvoices());
    yield put(clientsActions.fetchClients());

    yield put(actions.isModalOpen(false));

    notificationHelper.showSuccess(`Succès`, `Vos factures ont été ajoutées avec succès.`, 5);


  } catch (error) {
    const errorMessage = error?.response?.data?.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
  }  
}

function* removeIncoices(action){
  try {
    const {invoicesId} = action.payload;

    yield call(ApiProvider.axiosWithToken.post, "/invoices/bulk/delete",{invoicesId});
    
    yield put(actions.fetchInvoices());
    yield put(reminderActions.reqFetchReminders());
    notificationHelper.showSuccess(`Succès`, `Vos factures ont bien été supprimées.`, 5);

  } catch (error) {
    const errorMessage = error?.response?.data?.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
  }
}

export function* bulkAddInvoice(){
  yield takeLatest(actions.BULK_ADD_INVOICE,addInvoices);
}

export function* bulkRemoveInvoices(){
  yield takeLatest(actions.BULK_DELETE_INVOICE,removeIncoices)
}

export default function* rootSaga() {
  yield all([
    fork(reqFetchInvoices),
    fork(reqBulkArchiveInvoices),
    fork(exportToExcel),
    fork(bulkAddInvoice),
    fork(bulkRemoveInvoices)
  ])
}
