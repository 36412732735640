import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  users: [],
  config: {},
  configIsLoading: false,
  usersIsLoading: false,
  relances : [],
  relancesLoading : false,
  histories : [],
  openModal : false,
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.UPDATE_USERS:
      newState.users = action.payload;
      return newState;

    case actions.TOGGLE_USERS_LOADER:
      newState.usersIsLoading = action.payload;
      return newState;

    case actions.UPDATE_CONFIG:
      newState.config = action.payload;
      return newState;

    case actions.TOGGLE_CONFIG_LOADER:
      newState.configIsLoading = action.payload;
      return newState;

    case actions.UPDATE_RELANCES:
      newState.relances = action.payload;
      return newState;
    
    case actions.RELANCES_LOADIND:
      newState.relancesLoading= action.payload;
      return newState;

    case actions.OPEN_MODAL_RELANCE:
      newState.openModal = action.payload;
      return newState;

    case actions.UPDATE_HISTORY_RELANCE:
      newState.histories = action.payload;
      return newState;

    default:
      return state;
  }
}
