import React from 'react';
import { Button, Form, Input, Select, Upload, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import StyledModal from './StyleModal';
import apiConfig from '../../../config/api.config';
import mySendingBoxStatus from "../../../helpers/mySendingBoxStatus";
import courrierLabels from "../../../helpers/courrierLabels";
import suiviStatus from '../../../helpers/suiviStatus';
import statusLabel from '../../../helpers/statusLabel';

const { Option } = Select;

const ReminderDetailModal = ({
  formRef,
  openModal,
  clickedItem,
  selectedSuivi,
  annotation,
  histories,
  onCancel,
  onOk,
  onSuiviChange,
  onAddAnnotation,
  onCommentChange,
  onUploadFile,
  onRemoveFile
}) => {
  return (
    <Form ref={formRef}>
      <StyledModal 
        title={`${clickedItem?.reference} / ${clickedItem?.companyName}`}
        centered
        open={openModal}
        onCancel={onCancel}
        footer={[
          <Button key={uuidv4()} onClick={onOk} type="primary">
            Enregistrer
          </Button>,
        ]}
        width={1000}
      >
        <div className='modal-content'>
          <div className='m-head'>
            <div></div>
            <div className='text-principal'>
              Principal = <NumberFormat
                displayType={'text'}
                value={clickedItem?.mainBalance}
                decimalSeparator={','}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator={' '}
                suffix={'€'}
              />
            </div>
            <div className='option-suivi'>
              <span> Suivi </span>
              <Select value={selectedSuivi} placeholder="" style={{width:170}} onChange={onSuiviChange}>
                {Object.entries(suiviStatus).map(([key, label]) => (
                  <Option key={key} value={key}>
                    {label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          
          {/* HISTORY */}
          <div className='content-history'>
            {
              histories.map((history) => (
                <div key={uuidv4()}>
                  <div className='history-item'>
                    <div className='history-date'>
                      {moment(history.createdAt).format("DD/MM/YYYY")}
                    </div>
                    {/* REMINDERS */}
                    { history.actionType === "reminder" && (
                      <div className='history-details'>
                        <Button type='default' size='small' disabled={!history.details.documentUrl} href={history.details?.documentUrl} target={'_blank'} rel="noreferrer">
                          {courrierLabels[history.details.documentType]}
                        </Button>
                        { history.details.documentType === "mail_direct_recouvrement" && (
                          <p>à {history?.details?.formValues?.mailDrMail}</p> 
                        )}

                        <div className='mailing'>
                          <div className="m-status">{mySendingBoxStatus[history.details.mailingStatus]}</div>
                          <div className="m-numero">
                            <a target='_blank' href={`http://www.csuivi.courrier.laposte.fr/suivi/index?id=${history.details.mailingTrackingNumber}`} title='Suivi'>{history.details.mailingTrackingNumber}</a>
                          </div>
                          <Space direction="vertical" style={{ width: '100%' }}>
                            {history.details.filingProof && (
                              <Button type="default" href={history.details.filingProof} target="_blank" rel="noreferrer" style={{ width: '100%' }}>
                                Télécharger la preuve de dépôt
                              </Button>
                            )}
                            {history.details.deliveryProof && (
                              <Button type="default" href={history.details.deliveryProof} target="_blank" rel="noreferrer" style={{ width: '100%' }}>
                                Télécharger l'accusé de réception
                              </Button>
                            )}
                          </Space>
                        </div>
                      </div>
                    )}
                    {/* DEBITEUR */}
                    { history.actionType === "debtor" && (
                      <div>
                        <div className="d-status">{statusLabel[history.details?.status]} <span className='company-name'>par {clickedItem.companyName}</span></div>
                        {
                          history.details?.raison && (
                            <div className="d-message">{history.details?.raison}</div>
                          )
                        }
                        { 
                          history.details?.supportingDocument && (
                              <div className="d-document"><a href={`${apiConfig.baseUrl}/annotation/${history.details.supportingDocument}`} target='_blank' download>{history.details?.supportingDocument}</a></div>
                          )
                        }
                        {
                          history.details?.email && (
                            <div className="d-email">{history.details?.email}</div>
                          )
                        }
                      </div>
                    )}
                    {
                      history.actionType === "comment" && (
                        <div>
                          {
                            history.details?.comment && (
                              <div className="d-message">{history.details?.comment}</div>
                            )
                          }
                          { history.details?.supportingDocument && (
                            <div className="d-document"><a href={`${apiConfig.baseUrl}/annotation/${history.details.supportingDocument}`} target='_blank' download>{history.details?.supportingDocument}</a></div>
                          )}
                        </div> 
                      )
                    }
                  </div>
                  <hr/>
                </div>
              ))
            }
          </div>
          {/* HISTORY END */}
          
          {/* FORM */}
          {!annotation && (
            <div className='annotation'>
              <button onClick={onAddAnnotation}>
                + Ajoutez des commentaires
              </button>
            </div>
          )}
          <div className='form'>
            <div className='date'>
              {annotation?.date}
            </div>
            {
              annotation && (
                <div className='form-inputs'>
                  <Form.Item
                    name={"comment"}
                    rules={[
                      {
                        required: true,
                        message: `Veuillez saisir les commentaires`,
                      },
                    ]}
                  >
                    <Input.TextArea onChange={onCommentChange} placeholder='Ajouter des commentaires'/>
                  </Form.Item>
                  {!annotation?.file && (
                    <Upload accept={'.pdf'} maxCount={1} onChange={onUploadFile} fileList={[]}>
                      <Button className='upload-btn' size='middle'>Parcourir...</Button>
                    </Upload>
                  )}
                  <div>
                    {annotation?.file && (
                      <>
                        <span className="name-file">
                          {annotation?.file?.name}
                        </span>
                        <Button
                          className="remove-btn-file"
                          onClick={onRemoveFile}
                        >
                          <DeleteOutlined />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )
            }
          </div>
          {/* FORM END */}
        </div>
      </StyledModal>
    </Form>
  );
};

export default ReminderDetailModal;