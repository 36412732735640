import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Row, Col, Form, Spin, Button} from 'antd';
import connect from "react-redux/es/connect/connect";
import {colors} from "../../../helpers/appColors";
import {FormSelect, FormUpload} from "../../../helpers/forms";
import userActions from '../../../Redux/user/actions';
import RichTextEditor from './RichTextEditor';

class TabOptions extends Component{

  constructor(props) {
    super(props)
    this.state = {
      searchValue: null,
      fileLogo: null,
      filePaper: null,
      fileLogoVisible: false,
      filePaperVisible: false,
      objectMail: props.user.objectMail || null,
      contentMail : props.user.contentMail || null,
      activeEditor: null,
  
    };
  }


  editorRefs = {
    objectMail: React.createRef(),
    contentMail: React.createRef()
  }

  activeEditorRef =React.createRef()
  savedSelection = React.createRef()

  formRef = React.createRef();

  onFinish = (values) => {
    const dataToSent = {
      fileLogo: this.state.fileLogo ? this.state.fileLogo : null,
      filePaper: this.state.filePaper ? this.state.filePaper : null,
      objectMail: this.state.objectMail,
      contentMail: this.state.contentMail,
      ...values
    };

    console.log(dataToSent);
    this.props.requestUpdateOptions(dataToSent);
  };

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      fileLogoVisible: (user.logoType === 'custom'),
      filePaperVisible: (user.paperType === 'custom'),
      objectMail: user.objectMail,
      contentMail: user.contentMail
    })
  }

  handleOnValuesChange = (changedValue, allValues) => {
    if(changedValue.logoType){
      this.setState({fileLogoVisible: (changedValue.logoType === 'custom')})
    }
    if(changedValue.paperType){
      this.setState({filePaperVisible: (changedValue.paperType === 'custom')})
    }
  }

  handleFileLogo = file => {
    this.setState({ fileLogo: file });
    return false;
  };

  handleFilePaper = file => {
    this.setState({ filePaper: file });
    return false;
  };

  saveSelection = (editorRef) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      this.savedSelection.current = selection.getRangeAt(0);
      this.activeEditorRef.current = editorRef.current; // Stocke l'éditeur actif
    }
  };

  onObjectChange = (object) => {
    this.setState({ objectMail: object });
  }

  onContentChange = (content) => {
    this.setState({ contentMail: content });
  }

  insertTag = (tag) => {
    if (!this.activeEditorRef.current) {
      return;
    }

    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const span = document.createElement("span");
    span.style.color = "green";
    span.textContent = tag;
    span.setAttribute("contentEditable", "false");

    range.deleteContents();
    range.insertNode(span);

    range.setStartAfter(span);
    range.setEndAfter(span);
    selection.removeAllRanges();
    selection.addRange(range);

    if (this.activeEditorRef.current === this.editorRefs.objectMail.current) {
      this.setState({
        objectMail : this.activeEditorRef.current.innerHTML
      })
    } else if (this.activeEditorRef.current === this.editorRefs.contentMail.current) {
      this.setState({
        contentMail : this.activeEditorRef.current.innerHTML
      })
    }
  }

  resetContent = () => {
      const { user } = this.props;
      this.editorRefs.objectMail.current.innerHTML = user.objectMail
      this.editorRefs.contentMail.current.innerHTML = user.contentMail
      this.setState({
        objectMail : user.objectMail,
        contentMail : user.contentMail
      })
  }
  render(){
    const { user } = this.props;

    if(!user) return (<Spin spinning={true}>Chargement...</Spin>);

    const logoOptions = [
      {text: 'Direct Recouvrement', value: 'direct_recouvrement'},
      {text: 'Mon logo', value: 'custom'},
      {text: 'Aucun logo', value: 'none'}
    ];

    const paperOptions = [
      {text: 'Aucun en-tête', value: 'none'},
      {text: 'Mon en-tête', value: 'custom'}
    ];

    return (
      <Spin spinning={!user}>
        <Form onFinish={this.onFinish} ref={this.formRef} onValuesChange={this.handleOnValuesChange}>
          <div className='grey-block'>
            <h3>
              Mes impressions
              <Button
                type='default'
                style={{marginLeft: 20}}
                size='small' className='blue-btn' htmlType='submit'>
                Enregistrer
              </Button>
              <Button
                type='default'
                style={{marginLeft: 10}}
                size='small'
                onClick={() => this.formRef.current.resetFields()}
              >
                Annuler
              </Button>
            </h3>
            <Row gutter={16}>
              <Col span={8} offset={1} style={{display: 'flex', alignItems: 'center'}}>
                <strong>Quel logo sur le justificatif comptable ?</strong>
              </Col>
              <Col span={6} style={{display: 'flex', alignItems: 'center'}}>
                <FormSelect name='logoType' options={logoOptions} initialValue={user.logoType ? user.logoType : 'direct_recouvrement'}/>
              </Col>
              {
                this.state.fileLogoVisible && (
                  <>
                    <Col span={6} offset={9}>
                      <FormUpload
                        buttonLabel='Votre logo (format .jpg ou .png)'
                        name={`logo`}
                        valuePropName={`file`}
                        accept={`.jpg,.png`}
                        action={'#'}
                        multiple={false}
                        maxCount={1}
                        beforeUpload={this.handleFileLogo}
                      />
                    </Col>
                    <Col span={5}>
                        <img src={user.logoPath} alt='' style={{width: '100%'}} />
                    </Col>
                  </>
                )
              }
            </Row>
            <Row gutter={16}>
              <Col span={8} offset={1} style={{display: 'flex', alignItems: 'center'}}>
                <strong>Quel en-tête pour vos relances ?</strong>
              </Col>
              <Col span={6} style={{display: 'flex', alignItems: 'center'}}>
                <FormSelect name='paperType' options={paperOptions} initialValue={user.paperType ? user.paperType : 'none'}/>
              </Col>
              {
                this.state.filePaperVisible && (
                  <>
                    <Col span={6} offset={9}>
                      <FormUpload
                        buttonLabel='Papier en-tête (format .jpg)'
                        name={`paper`}
                        valuePropName={`file`}
                        accept={`.jpg`}
                        action={'#'}
                        multiple={false}
                        beforeUpload={this.handleFilePaper}
                      />
                    </Col>
                    <Col span={5}>
                      <img src={user.paperPath} alt='' style={{width: '100%'}} />
                    </Col>
                  </>
                )
              }
            </Row>
            <Row gutter={16}>
              <Col offset={9} span={15}>
                <p style={{color: colors.textRed, fontSize: '0.9em'}}>
                  Vous devez télécharger votre papier en-tête (format .jpg), si vous voulez activer l'option{' '}
                  <span style={{textDecoration: 'underline'}}>envoi par nos services</span> de vos courriers "Rappel d'échéance", "Avis d'impayé" et "Mise en demeure".
                </p>

                <p style={{fontSize: '0.9em'}}>
                  <span style={{textDecoration: 'underline'}}>Aide</span> :  Pour convertir un papier en-tête pdf au format jpeg, cliquer sur le lien{' '}
                  <a href='https://www.ilovepdf.com/fr/pdf_en_jpg' target='_blank' rel="noreferrer">https://www.ilovepdf.com/fr/pdf_en_jpg</a>, sélectionnez votre papier en-tête et convertissez le en jpeg !
                </p>
              </Col>
            </Row>
          </div>
          <div className='grey-block'>
            <h3>
               Mon mail amiable
                <Button
                  type='default'
                  style={{marginLeft: 20}}
                  size='small' className='blue-btn' htmlType='submit'>
                  Enregistrer
                </Button>
                <Button
                  type='default'
                  style={{marginLeft: 10}}
                  size='small'
                  onClick={this.resetContent}
                >
                  Annuler
                </Button>
              </h3>
              <Row>
                <Col span={24} offset={1}>
                  <p>Objet</p>
                  <RichTextEditor editorRef={this.editorRefs.objectMail} minHeight={"20px"} maxWidth={"500px"} defaultContent={this.state.objectMail} onChange={this.onObjectChange} onSelectionChange={()=>this.saveSelection(this.editorRefs.objectMail)} ></RichTextEditor>
                  <p>Message</p>
                  <RichTextEditor editorRef={this.editorRefs.contentMail} minHeight={"300px"} maxWidth={"800px"} defaultContent={this.state.contentMail} onChange={this.onContentChange} onSelectionChange={()=>this.saveSelection(this.editorRefs.contentMail)} ></RichTextEditor>
                  <p style={{marginTop:"20px"}}>Insérer</p>
                  <div className='insert-block'>
                      <Button type='default' size='small' onClick={()=>this.insertTag("{Référence relance}")}>Référence relance</Button>
                      <Button type='default' size='small' onClick={()=>this.insertTag("{total principal}")}>Principal</Button>
                      <Button type='default' size='small' onClick={()=>this.insertTag("{Pénalités de retard}")}>Pénalités de retard</Button>
                      <Button type='default' size='small' onClick={()=>this.insertTag("{Principal + pénalités de retard}")}>Principal + pénalités de retard</Button>
                      <Button type='default' size='small' onClick={()=>this.insertTag("{Nom débiteur}")}>Nom débiteur</Button>
                      <Button type='default' size='small' onClick={()=>this.insertTag("{Nom de l'entreprise}")}>Nom de l'entreprise</Button>
                  </div>
                </Col>
              </Row>
          </div>
        </Form>
      </Spin>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info
  }),
  {
    requestUpdateOptions: userActions.reqUpdateOptions
  }
)(TabOptions));