/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Button, Row, Col, Divider, Popconfirm, Tooltip, Card, Radio } from 'antd';
import _ from 'lodash';
import modalsActions from '../../../Redux/modals/actions';
import userAcrions from '../../../Redux/user/actions';
import PricingTag from "../Helpers/PricingTag";
import {FormCheckbox, FormInput, FormRadio, FormSelect,FormUpload} from "../../../helpers/forms";

import greenSticker from '../../../resources/images/general/vignette_verte.jpg';
import orangeSticker from '../../../resources/images/general/vignette_orange.jpg';
import redSticker from '../../../resources/images/general/vignette_rouge.jpg';
import NumberFormat from "react-number-format";
import {colors} from "../../../helpers/appColors";
import CustomModal from "./CustomModal.style";
import { hasAccessToSticker, canUseSticker } from '../../../helpers/userHelper';
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';

const radioOptions=[
  {value: 'soi', label: (<span>
    <Tooltip placement='top' title='Disponible à partir de la formule Starter'>
    A expédier soi-même{' '}
    </Tooltip>
  </span>
  )},
  {value: 'ecopli', label:(
    <span>
      Ecopli 2,80€ ht {' '}
      <Tooltip placement='top' title=' On imprime, on expédie pour vous et vous suivez la distribution du courrier, en temps réel, sur votre tableau de bord (Distribué, Retour…)'><QuestionCircleOutlined style={{cursor: 'pointer',color: colors.primaryBlack}} /></Tooltip>
    </span>
  )},
  {value: 'lrar', label:(
    <span>
      Recommandé avec AR 9,80€ ht {' '}
      <Tooltip placement='top' title=' On imprime, on expédie pour vous et vous suivez la distribution du courrier, en temps réel, sur votre tableau de bord (Distribué, Retour…)'><QuestionCircleOutlined style={{cursor: 'pointer',color: colors.primaryBlack}} /></Tooltip>
    </span>
  )}
];

const relanceOptions = [
    {
      value : "reminder",
      title: "Rappel d'échéance",
      description:
        "Optimal si vous n'avez pas été payé dans les délais impartis et souhaitez faire une première relance.",
    },
    {
      value : "unpaid_notice",
      title: "Avis d'impayé",
      description:
        "À sélectionner si votre client ne s'est pas manifesté à la suite de vos premières relances.",
    },
    {
      value : "mise_en_demeure",
      title: "Mise en demeure",
      description:
        "Votre situation n'est pas régularisée ? Envoyez une mise en demeure à votre client pour faire avancer les choses dans les plus brefs délais.",
    },
  ];

  const relanceProps =  {
    reminder : "reminder",
    unpaid_notice : 'unpaidNotice',
    mise_en_demeure : "miseEnDemeure"
  }



class Relance extends Component {

  state = {
    maxLength:45,
    filePaper: null,
    filePaperVisible: false,
    paperType :'',
    selectedRadioValue: null,
    disabledOptions: [],
    selectedRelance : null,
    showOptionEnvoie : false,
  };

  componentDidMount(){
    if(this.props.formRef.current){
      const { submitType ,actionType } = this.props.formRef.current.getFieldsValue();
      if (submitType && (submitType === "reminder" || submitType === "unpaid_notice" || submitType ==="mise_en_demeure")) {
        this.setState({ selectedRelance: submitType });
      }
    }
    const { user } = this.props;

    this.setState({
      paperType : user.paperType,
      filePaperVisible: (user.paperType === 'custom')
    })
  }

  onFinish = (key) => {
    if (this.props.formRef && this.props.formRef.current) {

      const values = this.props.formRef.current.getFieldsValue();

      this.props.formRef.current.setFieldsValue({
        [`${relanceProps[key]}DelegatedSending`]: this.state.selectedRadioValue.value,
        [`${relanceProps[key]}Address`]: values.adresse,
        [`${relanceProps[key]}Gender`]: values.gender,
        [`${relanceProps[key]}Zip`]: values.zip,
        [`${relanceProps[key]}City`]: values.city,
        [`${relanceProps[key]}HasStickers`]: values.hasStickers,
        [`${relanceProps[key]}SelectedSticker`]: values.selectedSticker,
      });
      const fieldsToUpdate = Object.keys(relanceProps).reduce((acc, key) => {
        acc[`${relanceProps[key]}CompanyName`] = 
          key === this.state.selectedRelance ? values.company : null;
        return acc;
      }, {});
      this.props.formRef.current.setFieldsValue(fieldsToUpdate);

    }
    this.props.formRef.current.setFieldsValue({submitType: this.state.selectedRelance});
    this.props.formRef.current.submit();
  };

  handleOnChange = (value) => {
    this.setState({
      filePaperVisible : value === 'custom',
      paperType : value
    });
  }

  updateOptions = () => {
    const dataToSent = {
      filePaper: this.state.filePaper ? this.state.filePaper : null,
      paperType : this.state.paperType
    }
    
    this.props.requestUpdateOptions(dataToSent);
  }

  handleFilePaper = file => {
    this.setState({ filePaper: file });
    return false;
  };

  componentDidUpdate(prevProps) {
    const { courrierRelanceVisible, myCompanies, user, formRef } = this.props;
    const hasVisibilityChanged = courrierRelanceVisible !== prevProps.courrierRelanceVisible;
    const hasCompaniesChanged = myCompanies !== prevProps.myCompanies;
  
    if ((hasVisibilityChanged || hasCompaniesChanged) && courrierRelanceVisible) {
      this.setState({
        paperType: user.paperType,
        filePaperVisible: user.paperType === 'custom',
      });

      if (formRef.current) {
        this.handleFormUpdate();
        const { actionType } = formRef.current.getFieldsValue();
        if(actionType === "cancel"){
          const isFreeTrialExpired = user.subscriptionType === 'Free' && moment().subtract(7, 'days').isAfter(moment(user.createdAt));
          this.setState({selectedRelance:null})
          formRef.current.setFieldsValue({actionType:'none',submitType:null,delegatedSending: isFreeTrialExpired ? 'ecopli' : 'soi'})
        }
      }
      this.updateRadioOptions();
    }
  }
  
  updateRadioOptions = () => {
    const { user } = this.props;
    const oneWeekAgo = moment().subtract(7, 'days');
    const isFreeTrialExpired = user.subscriptionType === 'Free' && oneWeekAgo.isAfter(moment(user.createdAt));
  
    // Définir la valeur par défaut de l'option radio
    const defaultOptionValue = isFreeTrialExpired ? 'ecopli' : 'soi';
  
    // Mise à jour des options radio avec la désactivation de 'soi' si nécessaire
    const updatedOptions = radioOptions.map(option => ({
      ...option,
      disabled: isFreeTrialExpired && option.value === 'soi',
    }));
  
    this.setState({
      selectedRadioValue: updatedOptions.find(option => option.value === defaultOptionValue),
      disabledOptions: updatedOptions,
    });

  };
  
  handleFormUpdate = () => {
    const { formRef, myCompanies } = this.props;
    const { submitType, companyId, gender, company, adresse, zip, city } = formRef.current.getFieldsValue();
  
    // Mise à jour de l'état du relance si nécessaire
    if (submitType && (submitType === "reminder" || submitType === "unpaid_notice" || submitType ==="mise_en_demeure")) {
      this.setState({ selectedRelance: submitType });
    }
  
    // Mise à jour des valeurs du formulaire si une entreprise est sélectionnée
    if (companyId) {
      const tempCompany = _.find(myCompanies, { id: parseInt(companyId) });
      if (tempCompany) {
        // Nettoyage des valeurs 'null'
        Object.keys(tempCompany).forEach(key => {
          if (tempCompany[key] === 'null') tempCompany[key] = null;
        });
  
        formRef.current.setFieldsValue({
          gender: gender || tempCompany.gender,
          company: company || tempCompany.name,
          adresse: adresse || tempCompany.address,
          zip: zip || tempCompany.zip,
          city: city || tempCompany.city,
        });
      }
    }
  };
  

  handleOnClickRadioGender = (e) => {
    const values = this.props.formRef.current.getFieldsValue();
    const {gender} = values;
    if(gender === e.target.value){
      this.props.formRef.current.setFieldsValue({
        gender: null
      })
    }
  }

  handleOnChangeRadioDelegatedSending = (e) => {
    const selectedValue = e.target.value;
    // Mise à jour de l'état pour la valeur sélectionnée
    this.setState({
      selectedRadioValue: radioOptions.find((option) => option.value === selectedValue),
    });
  };

  handleOnclickRadioStickers = (e) => {
    const values = this.props.formRef.current.getFieldsValue();
    const {selectedSticker} = values;
    if(selectedSticker === e.target.value){
      this.props.formRef.current.setFieldsValue({
        selectedSticker: null
      })
    }
  }

  handleAdresseChange = (e) => {
    const inputValue = e.target.value;
    const lines = inputValue.split("\n").map((line) => line.slice(0, 45));    
    const truncatedLines = lines.slice(0, 3);
    const truncatedValue = truncatedLines.join("\n");
    this.props.formRef.current.setFieldsValue({
      adresse: truncatedValue,
    });
  }

  handleRelanceClick = (value) => {
    this.setState((prevState) => ({
      selectedRelance: prevState.selectedRelance === value ? null : value,
    }), () => {
      this.props.formRef.current.setFieldsValue({
        submitType: this.state.selectedRelance === null ? null : value,
      });
    });
  }

  handleShowOption = () => {
    this.setState((prevState)=>({
      showOptionEnvoie  : !prevState.showOptionEnvoie
    }))
  }

  closeModal = () => {
  
    this.props.hideModal('courrierRelance')
  }

  render(){
    const { user,appDocuments } = this.props;


    const chartDocument = _.find(appDocuments, {name: 'charte_bbp'});

    const style = {
      modal: {
       textAlign: 'left',
      },
    };

    const disabled=!(user.paperType === 'custom');

    const defaultOption = this.state.disabledOptions.find(option => option.value === 'ecopli') || {};   

    const defaultRadioOption='ecopli';

    const paperOptions = [
      {text: 'Aucun en-tête', value: 'none'},
      {text: 'Mon en-tête', value: 'custom'}
    ];

    if(!user) return <></>;
    
    return (
      <CustomModal
        title={`Courrier de relance`}
        visible={this.props.courrierRelanceVisible}
        onCancel={this.closeModal}
        footer={null}
        style={style.modal}
        destroyOnClose={true}
      >
        <Row gutter={16} style={{textAlign: 'center', margin: '0', background: colors.primaryGreen}}>
          <Col span={6}><PricingTag title={'FREE'} subtitle={'Gratuit*'} active={'Free' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'STARTER'} subtitle={'Gratuit'} active={'Starter' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PREMIUM'} subtitle={'Gratuit'} active={'Premium' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PRIVILÈGE'} subtitle={'Gratuit'} active={'Privilege' === user.subscriptionType}/></Col>
          <Col span={24} style={{marginBottom: 10, color: "white"}}>
            <em>* Gratuit la première semaine</em>
          </Col>
        </Row>
          {/* BLOC RELANCE */}
          <Row gutter={16} justify='center' style={{padding: "0px 20px", margin : "0px",backgroundColor :'#f5f5f5'}}>
            <Col span={24}>
              <p style={{fontWeight:'bold',textAlign:'center',margin : '20px auto 0px auto'}}>Choisissez votre niveau de relance</p>
              <Row gutter={16}>
                  {relanceOptions.map((option)=>(
                  <Col md={8} key={option.value}>
                      <Card
                        style={{
                          textAlign : 'center',
                          border : "0px solid",
                          cursor : 'pointer',
                          backgroundColor : "transparent"
                        }}
                        onClick={()=>this.handleRelanceClick(option.value)}
                      >
                        <h3 style={{ color: colors.primaryGreen , textDecoration : "underline", fontWeight: 600 }}>{option.title}</h3>
                        <Radio
                            checked={this.state.selectedRelance === option.value}
                            onChange={() => this.handleRelanceClick(option.value)} // Clic sur le bouton radio
                            style={{ marginTop: '10px' }}
                          />
                        <div style={{minHeight: "115px"}}>  
                            <p style={{ fontSize: '12px', color : colors.textGrey }}><em>{option.description}</em></p>
                        </div>
                      </Card>
                  </Col>
                ))}
              </Row>
            </Col>
        </Row>
        <br/>
        <div style={{display:`${this.state.selectedRelance ? "block" : "none"}`}}>
              <p style={{textAlign: 'center'}}>
                <em>
                Saisissez les coordonnées du destinataire
                </em>
              </p>
              <Row gutter={16} style={{margin: 0}}>
                <Col span={4} offset={4} style={{textAlign: 'center', paddingTop: 20}}>
                  <FormRadio
                    onClick={this.handleOnClickRadioGender}
                    name={'gender'}
                    label={null}
                    initialValue={'none'}
                    id={'mister'}
                    options={[{value: 'Mme', label: 'Mme'},{value: 'M.', label: 'M.'}]}
                    layout={'horizontal'}
                  />
                </Col>
                <Col span={11}>
                  <FormInput
                    label={`Nom / Raison sociale du destinataire`}
                    alignLabel='center'
                    placeholder={`Raison sociale`}
                    name={`company`}
                    type='text'
                    rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
                    disabled={true}
                  />
                </Col>
                <Col span={15} offset={4}>
                  <FormInput
                    label={`Adresse`}
                    alignLabel='center'
                    placeholder={`Adresse (Maximum 3 lignes, 45 caractères par ligne)`}
                    name={`adresse`}
                    type='textArea'
                    maxLength={135}
                    rules={[{required: true, message: 'Ce champ est obligatoire'},{max : 134 ,  message:`Maximum 135 caractères`, warningOnly: true}]}
                    onChange={this.handleAdresseChange}
                  />
                </Col>
                <Col span={6} offset={4}>
                  <FormInput
                    label={`Code postal`}
                    alignLabel='center'
                    placeholder={`Code postal`}
                    name={`zip`}
                    type='text'
                    rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
                  />
                </Col>
                <Col span={9}>
                  <FormInput
                    label={`Ville`}
                    alignLabel='center'
                    placeholder={`Ville`}
                    name={`city`}
                    type='text'
                    maxLength={39}
                    rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false},{max:38,message:'Maximum 39 caractères'}]}
                  />
                </Col>
              </Row>

              <Row gutter={16} style={{backgroundColor: '#f5f5f5',padding: 20,marginTop: 20, marginLeft: 0, marginRight:0}}>
                <p onClick={()=>this.handleShowOption(true)} style={{cursor:'pointer',fontWeight:'bold',textAlign:"center",color:"red",margin:'0px auto'}}>+ <span style={{textDecoration:'underline'}}>Ajoutez des options d'envoi</span></p>
                <Col span={24} style={{ borderRadius: 5,  textAlign: 'center',display:`${this.state.showOptionEnvoie ? 'block' :'none'}`}}>
                  <br/>
                  <br/>
                  <strong style={{fontStyle: 'italic', textDecoration: 'underline'}}>Ajoutez un sticker BBP</strong> :<br/>
                  <div  style={{display:"none"}}>
                    <FormInput
                      type='hidden'
                      name={`hasStickers`}
                      initialValue={hasAccessToSticker(user)}
                    >
                    </FormInput>
                  </div>
                  {user.chartAcceptedAt ? '' : (
                      <>
                        <Col span={24} style={{textAlign:"center",marginTop:10,color:colors.primaryRed}}>
                          Pour utiliser nos stickers de couleur, vous devez accepter notre charte
                        </Col>
                        <Col span={24} style={{textAlign:"center",marginTop:10}}>
                            <Button type='ghost' target="_blank" rel="noreferrer" style={{marginRight:20}} href={chartDocument.url} >Télécharger</Button>

                            <Popconfirm
                              placement="top"
                              title={`Voulez-vous vraiment accepter la charte ?`}
                              onConfirm={()=>this.props.requestUpdateUser({chartAcceptedAt: moment().format('YYYY-MM-DD')})}
                              okText="Oui"
                              cancelText="Non"
                            >
                              <Button type={'primary'}  style={{marginLeft:20}}> Accepter</Button>
                            </Popconfirm>
                        </Col>
                      </>
                  )}
                  <br/>
                  <Col span={8} style={{textAlign:'center', display: 'inline-block'}}>
                    <label htmlFor={`green`}><img src={greenSticker} style={{width: '100%', border: 'solid lightgrey 1px', padding: 5, borderRadius: 5}}/></label>
                    <FormRadio
                      onClick={this.handleOnclickRadioStickers}
                      name={`selectedSticker`}
                      options={[{value: 'green'}]}
                      id='green'
                      disabled={!(user.chartAcceptedAt && canUseSticker(user, 'green'))}
                    />
                  </Col>
                  <Col span={8} style={{textAlign:'center', display: 'inline-block'}} >
                    <label htmlFor={`orange`}><img src={orangeSticker} style={{width: '100%', border: 'solid lightgrey 1px', padding: 5, borderRadius: 5}}/></label>
                    <FormRadio
                      onClick={this.handleOnclickRadioStickers}
                      name={`selectedSticker`}
                      options={[{value: 'orange'}]}
                      id='orange'
                      disabled={!(user.chartAcceptedAt && canUseSticker(user, 'orange'))}
                    />
                  </Col>
                  <Col span={8} style={{textAlign:'center', display: 'inline-block'}}>
                    <label htmlFor={`red`}><img src={redSticker} style={{width: '100%', border: 'solid lightgrey 1px', padding: 5, borderRadius: 5}}/></label>
                    <FormRadio
                      onClick={this.handleOnclickRadioStickers}
                      name={`selectedSticker`}
                      options={[{value: 'red'}]}
                      id='red'
                      disabled={!(user.chartAcceptedAt && canUseSticker(user, 'red'))}
                    />
                  </Col>
                  <br/>
                  <br/>
                  <strong style={{fontStyle: 'italic', textDecoration: 'underline'}}>Comment souhaitez-vous envoyer ce courrier ?</strong> :
                  <br/>
                  <br/>
                  <Col span={24} style={{display:'flex',alignItems:'center',paddingLeft:62}}>
                      <FormSelect onChange={this.handleOnChange} name='paperType' options={paperOptions} initialValue={user.paperType ? user.paperType : 'none'}/>
                      <Button
                        type='default'
                        style={{marginLeft: 25,marginBottom:6}}
                        onClick={this.updateOptions}
                        size='small' className='blue-btn'>
                        Enregistrer
                      </Button>
                  </Col>
                  {
                    this.state.filePaperVisible && (
                      <>
                        <Col span={20} style={{margin: '0px auto'}}>
                          <Row gutter={16}>
                              <Col span={11}>
                                  <FormUpload
                                    buttonLabel='Papier en-tête (format .jpg)'
                                    name={`paper`}
                                    valuePropName={`file`}
                                    accept={`.jpg`}
                                    action={'#'}
                                    multiple={false}
                                    beforeUpload={this.handleFilePaper}
                                  />
                              </Col>
                              <Col span={13}>
                                <img src={user.paperPath} alt='' style={{width: '100%'}} />
                              </Col>
                          </Row>
                        </Col>
                      </>
                    )
                  }
                </Col>
              </Row>
              <p style={{textAlign: 'center',marginTop:'15px'}}>
                    <FormRadio
                      onClick={(e) => this.handleOnChangeRadioDelegatedSending(e)}
                      name={'delegatedSending'}
                      label={'M.'}
                      id={'mister'}
                      options={this.state.disabledOptions}
                      layout={'horizontal'}
                      defaultValue={this.state.selectedRadioValue ? this.state.selectedRadioValue.value : undefined}
                    />
              </p>
              <p style={{textAlign: 'center', marginTop: 20}}>
                <em>Montant en principal:</em> <strong><NumberFormat
                displayType={'text'}
                value={this.props.globalBalance}
                decimalSeparator={','}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator={' '}
                suffix={'€'}
              /></strong><br/>
                <em>Pénalités de retard calculées:</em> <strong><NumberFormat
                displayType={'text'}
                value={this.props.globalPenalties}
                decimalSeparator={','}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator={' '}
                suffix={'€'}
              /></strong>
              </p>

              <div style={{textAlign: 'center'}}>
                <Button type='primary' onClick={()=>this.onFinish(this.state.selectedRelance)}>Visualiser</Button>
              </div>
            </div>
      </CustomModal>
    )
  }
}

export default connect(
  state => ({
    courrierRelanceVisible: state.modals.courrierRelanceVisible,
    user: state.user.info,
    globalPenalties: state.calculator.globalPenalties,
    globalBalance: state.calculator.globalBalance,
    myCompanies: state.calculator.myCompanies,
    appDocuments: state.app.documents
  }),
  {
    hideModal: modalsActions.hideModal,
    requestUpdateUser: userAcrions.requestUpdateUser,
    requestUpdateOptions : userAcrions.reqUpdateOptions
  }
)(Relance);