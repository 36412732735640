import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import StyleWrapper from './StyleWrapper';
import userActions from '../../../Redux/user/actions';
import { colors } from "../../../helpers/appColors";

import { Button } from 'antd';
import { TeamOutlined, BankOutlined, GiftOutlined, PercentageOutlined, SafetyOutlined, FilePdfOutlined, FileProtectOutlined, PoweroffOutlined , ContainerOutlined } from '@ant-design/icons';
import mainLogo from '../../../resources/images/logo-recouvrup.png';

class AdminHeader extends Component{

  render(){

    const { user } = this.props;

    const MenuButton = ({Icon, text, link, isExternal}) => {

      return (
        <li>
          <Button className={`menu-button${this.props.location.pathname === link ? ' active' : ''}`} type='text' href={link} target={isExternal ? '_blank': ''} rel={isExternal ? 'noreferrer' : null}>
            <Icon className='menu-icon'/>
            <div className='menu-title'>{text}</div>
          </Button>
        </li>
      );
    };

    return (
      <StyleWrapper>
        <div style={{width:'230px', padding: 10}}>
          <img src={mainLogo} className='main-logo' title="Recouvr'up - Plateforme de recouvrement 2.0" alt="Recouvr'up"/>
        </div>
        <ul>
          <MenuButton text={'Utilisateurs'} Icon={TeamOutlined} link={'/admin'}/>
          <MenuButton text={'Relances'} Icon ={ContainerOutlined} link={'/admin/relances'}/>
          <MenuButton text={'Int. légaux'} Icon={PercentageOutlined} link={'/admin/interest'}/>
          <MenuButton text={'BCE'} Icon={BankOutlined} link={'/admin/bce'}/>
          <MenuButton text={'Label'} Icon={SafetyOutlined} link={'/admin/label'}/>
          <MenuButton text={'Promotions'} Icon={GiftOutlined} link={'/admin/discount'}/>
          <MenuButton text={'Documents'} Icon={FileProtectOutlined} link={'/admin/documents'}/>
          <MenuButton text={'PDF'} Icon={FilePdfOutlined} link={'/admin/pdf'}/>
        </ul>
        <div style={{width:'230px', padding: 10, textAlign: 'right'}}>
          <Button style={{backgroundColor: colors.primaryRed, borderColor: colors.primaryRed, color: 'white'}} type='default' onClick={() => this.props.logout()} ><PoweroffOutlined/> Déconnexion</Button>
        </div>
      </StyleWrapper>
    )
  }
}

export default withRouter(connect(
  state => ({
    user: state.user.info
  }),
  {
    logout: userActions.logout
  }
)(AdminHeader));