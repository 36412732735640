import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../helpers/appColors";

const Content = styled.div`
    .rich-text{
      padding: 10px;
      width: 100%;
      background-color: white;
      border: 1px solid ${colors.border};
      border-radius: 5px;
      margin-bottom: 10px;
      div{
        font-weight: 600;
      }
      span{
        font-weight: 400;
      }
      &:focus{
        outline: none;
      }
    }
`

export default function RichTextEditor({editorRef,maxWidth,minHeight,defaultContent,onChange,onSelectionChange }) {

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = defaultContent;
    }
  }, []);

  const handleInput = () => {
    if (editorRef.current) {
      onChange(editorRef.current.innerHTML)
    }
  };

  return (
    <Content>
      <div
        ref={editorRef}
        contentEditable="true"
        onInput={handleInput}
        onMouseUp={onSelectionChange}
        onKeyUp={onSelectionChange}
        style={{
          minHeight:`${minHeight}`,
          maxWidth:`${maxWidth}`
        }}
        className="rich-text"
      ></div>
    </Content>
  );
}
