import React, { Component } from "react";
import StyleWrapper from "./StyleWrapper";
import HomeNavbar from "../HomeNavbar/HomeNavbar";
import HomeSignupModal from "../Modals/Signup";
import HomeLoginModal from "../Modals/Login";
import FooterNavbar from "../Footer/FooterNavbar";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as notificationHelper from "../../../helpers/notificationHelper";
import { Typography } from 'antd';
import apiConfig from "../../../config/api.config";

import { Button, Form, Input, Modal, notification } from "antd";

import { CheckOutlined, CopyOutlined } from "@ant-design/icons";

import espaceDebiteurActions from "../../../Redux/espaceBebiteur/actions";

class EspaceDebiteur extends Component {
  formRef = React.createRef();
  state = {
    copiedItem: null
  };

  onFinish = (values) => {
    const reference = `${values.reference1}-${values.reference2}-${values.reference3}`;
    this.props.fetchReminder(reference);
  };

  validateDigits = (_, value) => {
    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Le champ doit contenir seulement des chiffres")
    );
  };

  validateLetters = (_, value) => {
    if (!value || /^[a-zA-Z]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Le champ ne doit contenir que des lettres")
    );
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const reference = queryParams.get("reference");
    const status = queryParams.get("status");
    if (reference && status) {
      this.props.fetchReminder(reference);
      if (status === "success") {
        notificationHelper.showSuccess(
          "Success",
          "Votre transaction a été initié avec succes via Bridge. Merci pour votre confiance !", // message apers bridge api
          5
        );
      } else if (status === "error") {
        notificationHelper.showError("Erreur", "Une erreur est survenue lors de la transaction. ", 5);
      }
    }
  }

  handleCopy = (text, label) => {
    navigator.clipboard.writeText(text).then(() => {
      this.setState({ copiedItem: label });
      notification.success({
        message: 'Copié !',
        description: `${label} a été copié dans le presse-papier`,
        duration: 2,
      });
      
      // Réinitialiser l'icône après 2 secondes
      setTimeout(() => {
        this.setState({ copiedItem: null });
      }, 2000);
    });
  };

  render() {
    const { copiedItem } = this.state;
    const { computeStatus } = this.props;

    return (
      <StyleWrapper>
        <HomeNavbar />
        <HomeLoginModal />
        <HomeSignupModal />
        <div className="espace-debiteur">
          <div className="espace-debiteur__img"></div>
          <div className="espace-debiteur__content">
            <div className="asked-block">
              <div className="asked-block__title">
                <h1>ESPACE DÉBITEUR</h1>
                <h2>Vous venez de recevoir une relance et vous souhaitez</h2>
              </div>
              <div className="asked-block__wrapper_list">
                <ul>
                  <li>
                    {" "}
                    <CheckOutlined className="icon-items" /> Telecharger les documents (justificatif comptable, factures)
                  </li>
                  <li>
                    {" "}
                    <CheckOutlined className="icon-items" /> Mettre à jour le
                    statut de la relance
                  </li>
                  <li>
                    {" "}
                    <CheckOutlined className="icon-items" /> Confirmer le
                    paiement
                  </li>
                </ul>
              </div>
              <div className="asked-block__subtitle">
                <h2>
                  {" "}
                  Renseignez votre référence et accédez au dossier
                </h2>
              </div>
              <div className="asked-block__form">
                <h3>
                  <span>REFERENCE</span>
                </h3>
                <Form ref={this.formRef} onFinish={this.onFinish}>
                  <div className="asked-block__form_items">
                    <div className="asked-block__form_item">
                      <Form.Item
                        name={"reference1"}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir des chiffres",
                          },
                          {
                            validator: this.validateDigits,
                          },
                        ]}
                      >
                        <Input className="asked-block__form_input"  style={{ textTransform: 'uppercase', textAlign: 'center' }} />
                      </Form.Item>
                    </div>
                    <div>
                      <div className="separator"></div>
                    </div>
                    <div className="asked-block__form_item">
                      <Form.Item
                        name={"reference2"}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir des chiffres",
                          },
                          {
                            validator: this.validateDigits,
                          },
                        ]}
                      >
                        <Input className="asked-block__form_input"  style={{ textTransform: 'uppercase', textAlign: 'center' }} />
                      </Form.Item>
                    </div>
                    <div>
                      <div className="separator"></div>
                    </div>
                    <div className="asked-block__form_item">
                      <Form.Item
                        name={"reference3"}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir des lettres",
                          },
                          {
                            validator: this.validateLetters,
                          },
                        ]}
                      >
                        <Input
                          name="reference1"
                          className="asked-block__form_input"
                          style={{ textTransform: 'uppercase', textAlign: 'center' }}
                          onInput={(e) => {
                            e.target.value = e.target.value.toUpperCase();
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="asked-block__form_btn">
                    <Form.Item>
                      <Button
                        className="asked-block__form_btn_btn"
                        type="default"
                        htmlType="submit"
                      >
                        VALIDER
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Confirmation de paiement"
          centered
          open={computeStatus !== null}
          footer={null}
          onCancel={() => this.props.resetComputeStatus()}
        >
          <div style={{ textAlign: 'left' }}>
            <p>
              Vous approuvez la relance. Pour clôturer le dossier, veuillez envoyer le paiement 
              à votre créancier par le moyen de votre choix ou réaliser un virement sur le compte 
              bancaire de notre cabinet <Typography.Text strong>DIRECT RECOUVREMENT</Typography.Text> ci-dessous :
            </p>
            
            <div style={{ 
              background: '#f5f5f5', 
              padding: '15px', 
              borderRadius: '5px',
              marginTop: '15px',
              marginBottom: '15px'
            }}>
              <p style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
                IBAN : <Typography.Text strong>{apiConfig.iban}</Typography.Text>
                {copiedItem === 'IBAN' ? (
                  <CheckOutlined style={{ marginLeft: '8px', cursor: 'pointer', color: '#52c41a' }} />
                ) : (
                  <CopyOutlined 
                    style={{ marginLeft: '8px', cursor: 'pointer' }}
                    onClick={() => this.handleCopy(apiConfig.iban, 'IBAN')}
                  />
                )}
              </p>
              <p style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
                Référence à rappeler : <Typography.Text strong>{computeStatus?.reference}</Typography.Text>
                {copiedItem === 'Référence' ? (
                  <CheckOutlined style={{ marginLeft: '8px', cursor: 'pointer', color: '#52c41a' }} />
                ) : (
                  <CopyOutlined 
                    style={{ marginLeft: '8px', cursor: 'pointer' }}
                    onClick={() => this.handleCopy(computeStatus?.reference, 'Référence')}
                  />
                )}
              </p>
              <p style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
                Montant (€) : <Typography.Text strong>{(computeStatus?.mainBalance + computeStatus?.penalties).toFixed(2).toString().replace('.',',')}</Typography.Text>
                {copiedItem === 'Montant' ? (
                  <CheckOutlined style={{ marginLeft: '8px', cursor: 'pointer', color: '#52c41a' }} />
                ) : (
                  <CopyOutlined 
                    style={{ marginLeft: '8px', cursor: 'pointer' }}
                    onClick={() => this.handleCopy((computeStatus?.mainBalance + computeStatus?.penalties).toFixed(2).toString().replace('.',','), 'Montant')}
                  />
                )}
              </p>
            </div>
          </div>
        </Modal>
        <FooterNavbar />
      </StyleWrapper>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.user,
      espaceDebiteur: state.espaceDebiteur,
      computeStatus: state.espaceDebiteur.computeStatus
    }),
    {
      fetchReminder: espaceDebiteurActions.reqFetchReminder,
      resetComputeStatus: espaceDebiteurActions.resetComputeStatus
    }
  )(EspaceDebiteur)
);
