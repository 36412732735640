import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Button, Form, Modal, Tooltip, Switch } from 'antd';
import NumberFormat from "react-number-format";
import StyleWrapper from './StyleWrapper';
import orderActions from '../../../Redux/order/actions';
import { colors } from '../../../helpers/appColors';
import _ from "lodash";
import history from "../../../helpers/history";
import calculatorActions from "../../../Redux/calculator/actions";
import { QuestionCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import PdfViewer from './PdfViewer';

class CustomerOrder extends Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.pdfRef = React.createRef()
  }

  state = {
    urlPdf: '',
    hashSent: false,
    errorVisible: false,
    switchChecked: false,
  };

  formRef = React.createRef();

  onFinish = (values) => {
    const { submitType, orderId, hash, switchChecked } = values;
    this.props.finalizeOrder(submitType, orderId, hash, switchChecked);
  };

  handleSubmitType = (type, hash = null) => {
    if (this.props.match.params.orderId) {
      const { orderId } = this.props.match.params;
      const { switchChecked } = this.state;
      this.formRef.current.setFieldsValue({
        submitType: type,
        hash,
        orderId,
        switchChecked: switchChecked
      });
      this.formRef.current.submit();
    }
  };

  componentDidMount() {
    if (this.props.match.params.orderId) {
      const { orderId } = this.props.match.params;
      this.props.fetchOrder(orderId);
      this.props.toggleOrderSuccess(false);

      const queryParams = new URLSearchParams(window.location.search);
      const switchChecked = queryParams.get('switched');
      if (switchChecked === 'true') {
        this.setState({ switchChecked: true });
      }
      const error = queryParams.get('error');
      if (error) {
        this.setState({ errorVisible: true });
      }
    }
  }

  componentWillUnmount() {
    const iframe = this.iframeRef.current;
    if (iframe) {
      iframe.removeEventListener('load', this.addIframeEventListeners);
      this.removeIframeEventListeners();
    }
  }

  addIframeEventListeners = () => {
    const iframe = this.iframeRef.current;
    console.log('add');
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.addEventListener('mouseenter', this.handleIframeMouseEnter);
      iframe.contentWindow.addEventListener('mouseleave', this.handleIframeMouseLeave);
      iframe.contentWindow.document.addEventListener('contextmenu', this.disableRightClick);
    }
  }

  removeIframeEventListeners = () => {
    const iframe = this.iframeRef.current;
    console.log('remove');
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.removeEventListener('mouseenter', this.handleIframeMouseEnter);
      iframe.contentWindow.removeEventListener('mouseleave', this.handleIframeMouseLeave);
      iframe.contentWindow.document.removeEventListener('contextmenu', this.disableRightClick);
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const iframe = this.iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', this.addIframeEventListeners);
    }

    if (this.state.hashSent === false) {
      const queryParams = new URLSearchParams(window.location.search);
      const hash = queryParams.get('hash');

      if (hash && this.formRef.current) {
        this.handleSubmitType('stripe', hash);
        this.setState({ hashSent: true });
      }
    }
  }

  handleIframeMouseEnter = () => {
    alert('HEy')
    const iframe = this.iframeRef.current;
    if(iframe){
    iframe.style.pointerEvents = 'none';
   }
    document.body.style.cursor = 'not-allowed'; 
  }

  handleIframeMouseLeave = () => {
    document.body.style.cursor = 'default';
  }

  disableRightClick = (event) => {
    event.preventDefault();
  }

  handleCancel = () => {
    const { defaultFormValues, updateDefaultFormValues } = this.props;
    const data = _.cloneDeep(defaultFormValues);
    data.step = 3;    
    data.actionType = "cancel";
    updateDefaultFormValues(data);
    history.goBack();
  };

  onChangeSwitch = (checked) => {
    this.setState({
      switchChecked: checked
    });
  }

  render() {
    const { user, order, isLoading, orderSuccess, appPrices, documents, title2 } = this.props;
    const { percentWarrantOption } = appPrices;
    const percentMandateOption = percentWarrantOption[user.subscriptionType];
    const { switchChecked } = this.state;

    const cguDocument = _.find(documents, { name: 'cgu' });

    if (!order || !user) {
      return <></>;
    }

    let orderType, delegatedSending, delegatedSendingDescription;
    let isMandateOptionVisible = false;

    switch (order.documentType) {
      case 'written_proof':
        orderType = 'Justificatif';
        break;
      case 'mail_relance':
        orderType = 'Mail amiable';
        break;
      case 'reminder':
        orderType = 'Rappel';
        delegatedSending = 'ecopli';
        if (order.formValues.reminderDelegatedSending) {
          delegatedSending = order.formValues.reminderDelegatedSending;
        }
        break;
      case 'unpaid_notice':
        orderType = `Avis d'impayé`;
        delegatedSending = 'ecopli';
        if (order.formValues.unpaidNoticeDelegatedSending) {
          delegatedSending = order.formValues.unpaidNoticeDelegatedSending;
        }
        break;
      case 'mise_en_demeure':
        orderType = 'Mise en demeure';
        delegatedSending = 'ecopli';
        if (order.formValues.miseEnDemeureDelegatedSending) {
          delegatedSending = order.formValues.miseEnDemeureDelegatedSending;
        }
        break;
      case 'courrier_direct_recouvrement':
        orderType = 'Courrier Direct Recouvrement';
        isMandateOptionVisible = true;
        break;
      case 'mise_en_demeure_direct_recouvrement':
        orderType = 'Mise en demeure Direct Recouvrement';
        isMandateOptionVisible = true;
        break;
      case 'mail_direct_recouvrement':
        orderType = 'Mail Direct Recouvrement';
        isMandateOptionVisible = true;
        break;
      case 'injonction':
        orderType = 'Injonction de payer';
        break;
    }

    if (order.type === 'credit') {
      orderType = `Crédit de compte prépayé de ${order.priceWithoutTaxes * order.taxes}€ ttc`
    }

    switch (delegatedSending) {
      case `soi`:
        delegatedSendingDescription = `A expédier soi-même`;
        break;
      case `lrar`:
        delegatedSendingDescription = `Envoi par nos services - Recommandé avec AR 9,80€ ht`;
        break;
      case `ecopli`:
        delegatedSendingDescription = `Envoi par nos services - Ecopli 2,80€ ht`;
        break;
    }

    let formattedPrice = 'Gratuit';

    if (order.priceWithoutTaxes !== 0) {
      formattedPrice = <NumberFormat
        displayType={'text'}
        value={order.priceWithoutTaxes * order.taxes}
        decimalSeparator={','}
        fixedDecimalScale
        decimalScale={2}
        thousandSeparator={' '}
        suffix={'€ ttc'}
      />;
    }

    return (
      <StyleWrapper>
        <Modal
          title="C'est parfait !"
          visible={orderSuccess}
          closable={false}
          footer={<Button type='primary' href={'/'}>Retour au dashboard</Button>}
        >
          <p style={{ textAlign: 'center' }}>
            Votre commande a bien été prise en compte. Un email vous a été transmis
            sur votre adresse <strong>{user.email}</strong> avec tous les éléments correspondant
            à votre commande
          </p>
        </Modal>
        <Modal
          title="Oups !"
          visible={this.state.errorVisible}
          closable={true}
          onCancel={() => { this.setState({ errorVisible: false }) }}
          footer={null}
        >
          <p style={{ textAlign: 'center' }}>
            Une erreur s'est produite lors du paiement.<br />
            Il semble que votre moyen de paiement ait été refusé.
          </p>
        </Modal>
        <Form onFinish={this.onFinish} ref={this.formRef}>
          <Form.Item name={'submitType'} hidden={true} />
          <Form.Item name={'hash'} hidden={true} />
          <Form.Item name={'orderId'} hidden={true} />
          {
            isMandateOptionVisible && (<Form.Item name={'switchChecked'} hidden={true} />)
          }
          <Row gutter={16} style={{ justifyContent: 'center' }}>
            <Col span={13} className={'shadowed-block'}>
              <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Récapitulatif de votre commande
                <CloseCircleOutlined onClick={() => this.handleCancel()} style={{ color: 'black' }} />
              </h2>
              <br />
              <br />
              <div style={{ display: 'flex', width: '100%' }}>
                <PdfViewer pdfUrl = {this.props.documentToMail} orderType={order.documentType}/>
                <div style={{ width: '50%' }}>
                  <ul style={{ listStyle: 'none', fontSize: 16, lineHeight: 3 }}>
                    <li>+ Vous avez sélectionné la prestation suivante : <strong>{orderType}</strong></li>
                    {
                      (delegatedSending && delegatedSendingDescription) && (
                        <li>+ Option : <strong>{delegatedSendingDescription}</strong></li>
                      )
                    }
                    <li>+ Montant à régler : <strong>{formattedPrice}</strong></li>
                    {isMandateOptionVisible && (
                      <li className='option-block'>
                        <Switch
                          size='small'
                          checked={switchChecked}
                          style={{ marginRight: '10px', marginBottom: "1px" }}
                          onChange={this.onChangeSwitch}
                        />
                        Option mandat recouvrement
                        - <strong>honoraires de {percentMandateOption}% ht sur encaissements </strong>
                        {" "}
                        <Tooltip placement="top" title={`En acceptant cette option, vous confiez le recouvrement de votre créance à notre cabinet. Nous relancerons votre débiteur jusqu’à son paiement complet. Des honoraires ${percentMandateOption}% seront dus sur les sommes recouvrées (honoraires sur résultat uniquement)`}><QuestionCircleOutlined style={{ marginLeft: '5px' }} /></Tooltip>
                        {switchChecked && (
                          <div className='option-bloc__text'>
                            <i>Je mandate le cabinet Direct Recouvrement qui s’engage, après l’envoi de cette relance, à mettre en œuvre son savoir-faire pour entreprendre toutes les démarches utiles permettant le recouvrement de la créance, objet de cette commande, conformément aux conditions de l’article 5 des <a style={{ textDecoration: 'underline', color: colors.primaryBlue }} target='_blank' href={cguDocument.url}>Conditions Générales de Vente</a></i>
                          </div>
                        )}
                      </li>
                    )
                    }
                  </ul>
                </div>
              </div>
              {
                (order.priceWithoutTaxes === 0) ? (
                  <Row gutter={16} style={{ background: '#f9f9f9', borderRadius: 5, margin: '40px 0px', padding: 20, justifyContent: 'center' }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Button
                        type='default'
                        style={{
                          backgroundColor: colors.primaryOrange,
                          borderColor: colors.primaryOrange,
                          color: 'white',
                          margin: 20,
                        }}
                        size='large'
                        onClick={() => this.handleSubmitType('finalize')}
                        loading={isLoading}
                      >
                        Valider ma commande
                      </Button><br />
                      {(order.documentType === 'reminder') || (order.documentType === 'unpaid_notice') || (order.documentType === 'mise_en_demeure') ? <span style={{ fontSize: '17px' }}>Votre courrier vous sera remis par mail</span>
                        : (order.documentType === 'mail_direct_recouvrement' ? <span style={{ fontSize: '17px' }}>Nous vous mettrons en copie du mail adressé à votre débiteur pour un maximum de contrôle et de transparence.</span>
                          : order.documentType === 'mail_relance' ? (<span>Votre mail, pour lequel vous êtes placé en copie, sera envoyé aussitôt</span>)  :  <span style={{ fontSize: '17px', color: 'black' }}>Vous recevrez par mail le montant de vos pénalités de retard ainsi que votre justificatif comptable</span>)}
                     
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={16} style={{ background: '#f9f9f9', borderRadius: 5, margin: '40px 0px', padding: 20, justifyContent: 'center' }}>
                    {
                      order.type !== 'credit' && (
                        <Col span={12} style={{ textAlign: 'center' }}>
                          <Button
                            type='default'
                            style={{
                              backgroundColor: colors.primaryOrange,
                              borderColor: colors.primaryOrange,
                              color: 'white',
                              margin: 20,
                            }}
                            size='large'
                            onClick={() => this.handleSubmitType('prepaid')}
                            loading={isLoading}
                          >
                            Réglement en prépaiement
                          </Button>
                          <br />
                          <strong>Solde actuel : <NumberFormat
                            displayType={'text'}
                            value={user.prepaidAmount}
                            decimalSeparator={','}
                            fixedDecimalScale
                            decimalScale={2}
                            thousandSeparator={' '}
                            suffix={'€ ttc'}
                          /></strong>
                          <br />
                          {
                            (user.prepaidAmount === 0) && (
                              <em style={{ color: colors.primaryRed }}>Veuillez créditer votre compte</em>
                            )
                          }
                        </Col>
                      )
                    }
                    <Col span={12} style={{ textAlign: 'center' }}>
                      <Button
                        type='default'
                        style={{
                          backgroundColor: colors.primaryOrange,
                          borderColor: colors.primaryOrange,
                          color: 'white',
                          margin: 20,
                        }}
                        size='large'
                        loading={isLoading}
                        onClick={() => { this.props.createCheckoutSession(order.id, switchChecked , order.type === "credit"  ) }}
                      >
                        Réglement par Carte Bancaire
                      </Button>
                      <br />
                      <strong>Powered by Stripe </strong>
                    </Col>
                    {(order.documentType === 'mail_direct_recouvrement' || order.documentType === 'courrier_direct_recouvrement' || order.documentType === 'mise_en_demeure_direct_recouvrement') ? <span style={{ fontSize: '14px' }}>Notre courrier sera adressé à votre débiteur sous 24h ouvrées. Suivez ensuite son expédition depuis votre tableau de bord. </span> :
                      (order.documentType === 'injonction' ? <span style={{ fontSize: '17px', textAlign: 'center' }}>Nous rédigeons la requête et l’envoyons au tribunal après vérification de vos pièces. <br />En cas de manquement, nous prendrons contact avec vous.</span> : <span style={{ fontSize: '17px' }}>Votre courrier sera expédié sous 24h ouvrées</span>)}
                  </Row>
                )
              }
            </Col>
          </Row>

        </Form>
      </StyleWrapper>
    )
  }
}

export default withRouter(connect(
  state => ({
    user: state.user.info,
    appPrices: state.app.prices,
    order: state.order.current,
    isLoading: state.order.isLoading,
    orderSuccess: state.order.orderSuccess,
    paymentIntentSecret: state.order.paymentIntentSecret,
    defaultFormValues: state.calculator.defaultFormValues,
    documents: state.app.documents,
    documentToMail: state.order.documentToMail
  }),
  {
    fetchOrder: orderActions.reqFetchOrder,
    finalizeOrder: orderActions.reqFinalizeOrder,
    toggleOrderSuccess: orderActions.toggleOrderSuccess,
    createCheckoutSession: orderActions.reqCreateCheckoutSession,
    updateDefaultFormValues: calculatorActions.updateDefaultFormValues,
  }
)(CustomerOrder));