export default  {
  'courrier_direct_recouvrement':'Courrier DR',
  'injonction':'Injonction',
  'mail_direct_recouvrement':'Mail DR',
  'mise_en_demeure_direct_recouvrement':'Mise en demeure DR',
  'mise_en_demeure':'Mise en demeure',
  'reminder':'Rappel',
  'unpaid_notice':'Avis d\'impayé',
  'written_proof': 'Justificatif comptable',
  'mail_relance' : "Mail amiable"
};