const actions = {
  REQ_FETCH_CLIENTS: "REQ_FETCH_CLIENTS",
  UPDATE_CLIENTS: "UPDATE_CLIENTS",
  REQ_MODIFY_CLIENTS: "REQ_MODIFY_CLIENTS",
  REMOVE_CLIENTS: "REMOVE_CLIENTS",
  IS_LOADING: "IS_LOADING",
  IS_OPEN: "IS_OPEN",
  MODIFY_CLIENTS_DONE : "MODIFY_CLIENTS_DONE",
  EXPORT_CLIENTS_TO_EXCEL : "EXPORT_CLIENTS_TO_EXCEL",
  REQ_ADD_CLIENTS : "REQ_ADD_CLIENTS",
  ADD_CLIENT_SUCCESS: 'ADD_CLIENT_SUCCESS',
  ADD_CLIENT_RESET: 'ADD_CLIENT_RESET',


  fetchClients: () => ({
    type: actions.REQ_FETCH_CLIENTS,
  }),

  updateClients: (data) => ({
    type: actions.UPDATE_CLIENTS,
    payload: data,
  }),

  modifyClients: (id,data) => ({
    type: actions.REQ_MODIFY_CLIENTS,
    payload: { id, data },
  }),

  modifyClientsDone: () => ({
    type: actions.MODIFY_CLIENTS_DONE,
  }),

  removeClients: (ids) => ({
    type: actions.REMOVE_CLIENTS,
    payload: ids,
  }),

  isLoading: (isLoading) => ({
    type: actions.IS_LOADING,
    payload: isLoading,
  }),

  isOpen : (isOpen) => ({
    type : actions.IS_OPEN,
    payload : isOpen
  }),

  exportClientsToExcel : () => ({
    type : actions.EXPORT_CLIENTS_TO_EXCEL,
  }),
  
  addClient : (data) => ({
    type : actions.REQ_ADD_CLIENTS,
    payload : { data }
  }),

  addClientSuccess: (client) => ({
    type: actions.ADD_CLIENT_SUCCESS,
    payload: client
  }),

  addClientReset: () => ({
    type: actions.ADD_CLIENT_RESET
  })
};

export default actions;
