import styled from "styled-components";
import { colors } from "../../../helpers/appColors";

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 40px 20px 150px;

  .link{
    padding-top: 8px;
    padding-left: 2px;
  }
  .link-file{
    display: inline-block;
    color: black;
    text-decoration: underline;
    margin-right: 2px;
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    vertical-align: middle;
  }

  .upload-file{
    width:100%;
  }

  #step-menu {
    margin-top: 30px;
    justify-content: center;
  }

  #new-recovery-btn {
    z-index: 1000;
    color: ${colors.primaryGreen};
    background-color: white;
    border: solid 1px ${colors.primaryGreen};
    font-size: 1.4em;
    position: absolute;
    right: 40px;
    top: 30px;
    border-radius: 5px;
    padding: 2px 10px;
  }

  #new-recovery-btn:hover {
    color: white;
    background: ${colors.primaryGreen};
  }

  th {
    font-size: 12px;
  }

  table tr td {
    font-size: 12px;
  }

  .ant-table-container {
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-thead > tr > th {
    border-top:1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }

  .ant-table-selection-col {
    width: 50px;
  }

  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border-right: 1px solid #f0f0f0;
  }

  .detail-column {
    border: 0px solid!important;;
    background-color: white!important;;
  }

  
  .row-suspendue{
    background-color: ${colors.suspendue};
  }

  .row-reglee {
    background-color: ${colors.reglee};
  }

  .row-reglementEnCours {
    background-color: ${colors.reglementEnCours};
  }

  .row-approuvee {
    background-color: ${colors.approuvee};
  }

  .row-abandoned{
    background-color: ${colors.abandoned};
  }

  .row-refusee{
    background-color: ${colors.refusee};
  }

  .btn-link{
      text-decoration: none;
      cursor: pointer;
      background: none;
      padding: 2px;
      border: none;
  }
  .btn-link:hover{
      color: ${colors.primaryGreen};
  }
`;

export default StyleWrapper;
