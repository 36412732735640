import moment from "moment";

export function shortenFileName(fileName, maxLength) {
  const dotIndex = fileName.lastIndexOf(".");
  const extension = dotIndex === -1 ? "" : fileName.substring(dotIndex + 1);
  const fileNameWithoutExtension =
    dotIndex === -1 ? fileName : fileName.substring(0, dotIndex);

  if (fileNameWithoutExtension.length <= maxLength) {
    return fileName;
  }

  const ellipsisLength = 3;
  const shortenedFileNameWithoutExtension = fileNameWithoutExtension.substring(
    0,
    maxLength - ellipsisLength
  );

  return shortenedFileNameWithoutExtension + "..." + extension;
}

export function IdNameCompany(value) {
  const indexTiret = value.indexOf("-");
  if (indexTiret !== -1) {
    const id = value.substring(0, indexTiret);
    const companyName = value.substring(indexTiret + 1);
    return {id, companyName};
  } else {
    return {id: null, companyName: null};
  }
}

export function randomString(length,letterOnly){
  var result           = '';
  var characters       = letterOnly ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
  }
  return result;
}

export function getCompanyName(companyId,customers) {
  const company = customers.find(c => c.id === parseInt(companyId));
  return company ? company.name : null;
}

export function getCompanyEmail(companyId,customers) {
  const company = customers.find(c => c.id === parseInt(companyId));
  return company ? company.email : null;
}

export function generateReference(reference, userId) {
  const today = moment().format('YYMMDD');
  return reference || `${today}-${userId}-${randomString(4, true)}`;
}

export function replaceTags(content, tags) {

  const regexs= {
    reference: /<span[^>]*style="color:\s*green\s*;?[^>]*">\{Référence relance\}<\/span>/g ,
    principal: /<span[^>]*style="color:\s*green\s*;?[^>]*">\{total principal\}<\/span>/g,
    penalite: /<span[^>]*style="color:\s*green\s*;?[^>]*">\{Pénalités de retard\}<\/span>/g,
    balance:  /<span[^>]*style="color:\s*green\s*;?[^>]*">\{Principal \+ pénalités de retard\}<\/span>/g,
    companyName: /<span[^>]*style="color:\s*green\s*;?[^>]*">\{Nom débiteur\}<\/span>/g,
    entrepriseName:  /<span[^>]*style="color:\s*green\s*;?[^>]*">\{Nom de l'entreprise\}<\/span>/g
  }

  let newContent = content;

  for (const tag in tags) {
    if (tags.hasOwnProperty(tag)) {
      const regex = regexs[tag];
      newContent = newContent.replace(regex, `${tags[tag]}`);
    }
  }

  return newContent;
}